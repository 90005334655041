// Login page Start

.customer-account-login{
	.page-title-wrapper{
		text-align: center;
    	margin: 20px 0px 15px;

    	h1{
    		text-transform: uppercase;
    		margin-bottom: 2rem;
    	}
	}

	.login-container{
		.block.block-customer-login{

		    border:1px solid #000000;
		    padding: 40px;

		    .block-content{
		    	fieldset.fieldset.login{
		    		border: none;
				    margin-bottom: 20px;
				    padding: 0;
				    &:after {
					    margin: 10px 0 0;
					    content: attr(data-hasrequired);
					    display: block;
					    letter-spacing: normal;
					    word-spacing: normal;
					    color: #e02b27;
					    font-size: .9rem;
					    margin-top: 30px;
					    margin-left: 0 !important;
					}

				    label.label {
					    background: none;
					    padding: 0;
					    font-size: 1rem;
					}
					.control{
						width: 100% !important;

							input {
								background: #fff;
								background-clip: padding-box;
								border: $input-border;
								font-size: 18px;
								height: 52px;
								line-height: 24px;
								padding: 12px 15px;
								vertical-align: baseline;
								width: 100%;
								box-sizing: border-box;
							}
					}
					.actions-toolbar{
						    display: flex;
						    flex-direction: column;
						    margin: 0;

						.primary{
							button {
							    width: 100%;
							    font-size: 1.1rem;
							    padding-top: 20px;
							    padding-bottom: 20px;
							    text-transform: uppercase;
							}
						}
						.secondary{
							margin-top: 20px;
						}
					}
		    	}
		    	
		    }
		}
		.block.block-new-customer{
			border:1px solid #000000;
		    padding: 40px;
		    

		    .actions-toolbar{
		    	margin-top: 25px;

		    	.primary{
		    		width: 100%;

		    		a.action.create.primary {
					    width: 100%;
					    font-size: 1.1rem;
					    padding-top: 20px;
					    padding-bottom: 20px;
					    text-transform: uppercase;
					    border-radius: 50px;
					}
		    	}
		    }
		}
	}
}

// Login page ENDS
.field {
					label.label {
					    background: none;
					    color: #000000;
					    padding: 0;
					    font-size: 1rem;
					}
					.control{
						input {
						    background: #fff;
						    background-clip: padding-box;
						    border: $input-border;
						    font-size: 18px;
						    height: 52px;
						    line-height: 24px;
						    padding: 12px 15px;
						    vertical-align: baseline;
						    width: 100%;
						    -webkit-box-sizing: border-box;
						    box-sizing: border-box;
						}
					}
				}
// Register page Start

.customer-account-create{
	.page-title-wrapper{
		text-align: center;
    	margin: 20px 0px 15px;

    	h1{
    		text-transform: uppercase;
    		margin-bottom: 2rem;
    	}
	}
	form.form-create-account{
		display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100% !important;

	    fieldset.fieldset.create.info{
	    	    width: 48%;
			    margin-bottom: 2%;
			    border:1px solid #000000;
			    padding: 40px;
			    border: none;
			    @include breakpoint(small down) {
			    	width: 100%;
			     }
			    legend.legend {
				    margin-left: 0 !important;
				    font-size: 1.8rem;
				    border-bottom: 1px solid #e8e8e8;
				    margin-bottom: 20px;
				    padding-bottom: 20px;
				    font-weight: 500;
				}
				.field {
					label.label {
					    background: none;
					    color: #000000;
					    padding: 0;
					    font-size: 1rem;
					}
					.control{
						input {
						    background: #fff;
						    border: 1px solid #000000;
						    font-size: 18px;
						    height: 52px;
						    line-height: 24px;
						    padding: 12px 15px;
						    vertical-align: baseline;
						    width: 100%;
						}
					}
				}
	    }
	    fieldset.fieldset.create.account {
			    width: 48%;
			    margin-bottom: 2%;
			    border:1px solid #000000;
			    padding: 40px;
			    border: none;
			   @include breakpoint(small down) {
			    	width: 100%;
			     }
			    legend.legend {
				    margin-left: 0 !important;
				    font-size: 1.8rem;
				    border-bottom: 1px solid #e8e8e8;
				    margin-bottom: 20px;
				    padding-bottom: 20px;
				    font-weight: 500;
				}
				.field {
					label.label {
					    background: none;
					    color: #000000;
					    padding: 0;
					    font-size: 1rem;
					}
					.control{
						input {
						    background: #fff;
						    background-clip: padding-box;
						    border: 1px solid #000000;
						    font-size: 18px;
						    height: 52px;
						    line-height: 24px;
						    padding: 12px 15px;
						    vertical-align: baseline;
						    width: 100%;
						}
					}
				}
			}
		.actions-toolbar {
		    width: 100%;
		    margin-left: 0 !important;
		    justify-content: center;
		    align-items: center;
		    text-align: center;
		    display: flex;
		    margin-top: 20px;
    		flex-direction: column;

    		.primary{
		    		width: 100%;
					justify-content: center;
					text-align: center;
					align-items: center;
					 display: flex;

		    		button.action.submit.primary {
					    width: 50%;
					    font-size: 1.1rem;
					    padding-top: 20px;
					    padding-bottom: 20px;
					    text-transform: uppercase;
					}
		    	}
		}
	}
}
// Register page Ends

// My account starts

.account{
	.page-title-wrapper{
		text-align: center;
    	text-transform: uppercase;
	}
	&.customer-account-edit .columns .column.main{
		width: 100% !important;
	}
	.columns{
		.column.main {
    		width: 75% !important;

    		.message.info.empty {
			    background: #f7f7f7;
			    color: #111111;
			    font-size: 17px;
			    border-top: 3px solid #111;

			    & > *:first-child:before{
				    color:#111111;
				    font-size: 22px;
				    line-height: 30px;
				}
			}
			fieldset.fieldset {
			    border: none;
			    background: #f7f7f7;
			    padding: 40px;
			}
		}
		.sidebar.sidebar-main {
		    width: 25% !important;

		    .block.account-nav{
		    	#account-nav{
		    		background: #fff;
				    box-shadow: 0 3px 8px 0 rgba(0,0,0,.1);
				    border-radius: 1px;
				    padding:0;

				    ul{
				    	li.nav.item{
				    		a {
							    border: 0;
							    font-size: 14px;
							    color: $primary;
							    font-weight: 400;
							    text-transform: uppercase;
							    padding: 1.4rem 1.25rem 1.4rem 1.25rem;
							    position: relative;
							    word-break: break-all;
							    border-radius: 0 !important;
							}
				    	}
				    	li.nav.item.current{
				    		strong {
							    font-size: 14px;
							    color: $primary;
							    font-weight: 600;
							    text-transform: uppercase;
							    padding: 1.4rem 1.25rem 1.4rem 1.25rem;
							    position: relative;
							    word-break: break-all;
							    border-radius: 0 !important;
							    border-width: 5px;
							    background-color: #f7f7f7;
							    border-color: $primary;
							}
				    	}
				    }
		    	}
		    }
		}
	}
}
// My account ENDS

// Forgot password STARTS

.customer-account-forgotpassword {
	.page-title-wrapper {
	    text-align: center;
	    margin: 20px 0px 15px;

	    h1 {
		    text-transform: uppercase;
		    margin-bottom: 2rem;
		}
	}
	.form.password.forget{
		    margin: 0 auto;
		    padding: 40px;
		    border: 1px solid #111;

		    fieldset.fieldset {
			    border: none;
			}

		    .actions-toolbar{
		    	display: flex;
			    flex-direction: column;
			    width: 100%;
			    margin-left: 0 !important;
			    justify-content: center;
			    align-items: center;

			    .primary{
			    	button{
			    		width: 100%;
					    font-size: 1.1rem;
					    padding-top: 20px;
					    padding-bottom: 20px;
					    text-transform: uppercase;
			    	}
			    }
		    }
	}
}
// Forgot password ENDS

.abs-account-blocks .block-title>strong, .abs-account-blocks .block-title>span, .account .legend>strong, .account .legend>span, .form-giftregistry-search .legend>strong, .form-giftregistry-search .legend>span, .block-giftregistry-results .block-title>strong, .block-giftregistry-results .block-title>span, .block-giftregistry-shared-items .block-title>strong, .block-giftregistry-shared-items .block-title>span, .block-wishlist-search-form .block-title>strong, .block-wishlist-search-form .block-title>span, .block-wishlist-search-results .block-title>strong, .block-wishlist-search-results .block-title>span, .multicheckout .block-title>strong, .multicheckout .block-title>span, .multicheckout .block-content .title>strong, .multicheckout .block-content .title>span, .customer-review .review-details .title>strong, .customer-review .review-details .title>span, .paypal-review .block .block-title>strong, .paypal-review .block .block-title>span, .account .column.main .block:not(.widget) .block-title>strong, .account .column.main .block:not(.widget) .block-title>span, .multicheckout .block-title>strong, .multicheckout .block-title>span, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title>strong, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title>span, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title>strong, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title>span, .sales-guest-view .column.main .block:not(.widget) .block-title>strong, .sales-guest-view .column.main .block:not(.widget) .block-title>span{
	font-size: 24px;
	font-weight: 400;
}
.paypal-review .block .box-title>span, .account .column.main .block:not(.widget) .box-title>span, .multicheckout .box-title>span, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title>span, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title>span, .sales-guest-view .column.main .block:not(.widget) .box-title>span{
	font-size: 20px;
}
.account {
	.abs-account-block-font-size, .block-addresses-list address, .box-billing-address .box-content, .box-shipping-address .box-content, .box-address-billing .box-content, .box-address-shipping .box-content, .box-information .box-content, .block-balance-giftcard .block-content, .block-reviews-dashboard .product-name{
		font-size: 16px;
	}
}
.block-collapsible-nav .item.current a, .block-collapsible-nav .item.current>strong{
	border-color: $primary;
}

//My account Mobile
@include breakpoint(small down) {
	.account .column.main .block:not(.widget) .block-content .box{
		    padding: 10px;
	    	border: 1px solid rgba(0,0,0,0.2);
	}
	.account .column.main .block:not(.widget) .block-title{
		border: none;
	    padding-bottom: 0;
	    margin-bottom: 15px;
	}
	.order-details-items {
		.order-title{
			border: none;
			font-size: 18px;
			text-transform: uppercase;
			
				>strong{
				font-size: 18px;
	    		font-weight: 500;
			}
		}

		
		.product-item-name {
		    font-size: 15px;
		}
		.col.price .price-excluding-tax{
			color: #000;
	    	font-size: 16px
		}
	}
	.order-items.table-wrapper .col.subtotal,
	.order-items.table-wrapper .col.price{
		text-align: left;
	}
	.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr td[data-th]:before, .table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr th[data-th]:before{
		color: $primary;
	}
}