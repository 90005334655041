//category page
.products,
.moo-products-wrapper{

	.product-item{
		display: flex;
		margin-left: 0 !important;
		width: 100% !important;
		margin-bottom: 30px;

		&:hover .product-item-info{
			box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
			border:1px solid $primary;
			
		}
		.product-item-info{
			width: 100%;
			border:1px solid transparent;
			transition: all .2s ease-in-out;

			a.product{
				display: block;
				position: relative;

				.product-image-container{
					width: 100% !important;
				}
			}

			.product-item-details{
				padding: 0 10px;

				p.category-brands{
					color: $moo-brands-title;
					font-size: $moo-brands-title-font;
					text-transform: uppercase;
					font-weight: 300;
					margin-bottom: 0px;
					margin-top: 4px;
				}
				.product-item-name{
					line-height: 22px;
					height: 41px;
    				overflow: hidden;

					a{
						color: $moo-product-title;
						font-size: $moo-product-title-font;
						text-transform: uppercase;
						font-weight: $moo-product-item-font-weight;
						text-decoration: none;
						line-height: 1;
					}
				}

				.price-box{
					display: flex;
					margin: 0px 0px 10px 0px;
					.price-label{
						display: none;
					}
					.price{
						font-size: $moo-product-price-font;
						color: $moo-product-price-color;
						font-weight: $moo-product-item-font-weight;
					}
					.old-price{
						margin-left: 10px;

						.price{
							//color: rgba($moo-product-price-color,0.24);
							opacity: 0.24;
						}
					}
					//for b2b
					&.no-price{
						display: block;
						a{
							font-size: 14px;
							font-weight: 600;
							line-height: 20px;
							color: $primary;
						}
					}
				}

				.product-reviews-summary{
					margin: 0px;
					display: none;//temp hide
				}
				.product-item-actions{
					display: none;//temp hide
				}
			}
		}
		.product-image-photo{
			//transition: all .9s ease-in-out;
			//transform: scale(1);
		}
		//hover effect
		&:hover .have-environment{
			.product-image-photo{
				//transform: scale(1.1);
			}
			.product-image-container:not(.moo-second-image) .product-image-wrapper{
				opacity: 0;
			}
			&.product-item-photo span.product-image-container.moo-second-image .product-image-wrapper{
				opacity: 1;
			}
		}
		.product-image-wrapper{
			transition: opacity .5s ease-in-out;
		}
		.product-item-photo{
			span.product-image-container.moo-second-image{
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;

				.product-image-wrapper{
					height: 100%;
					z-index: 2;
					opacity: 0;
					display: flex;
				    justify-content: center;
				    align-items: center;
				}
			}
		}

		//swatches option

		.swatch-attribute-options{
			.swatch-option{
				border:1px solid rgba(#979797,0.1);
				width: 20px;
			    height: 20px;
			    border-radius: 50%;
			    padding: 0;
			    display: flex;
			    min-width: 10px;
			    text-align: center;
			    justify-content: center;
			    font-size: 10px;
			    outline: none;

			    &:hover{
			    	outline: none;
			    	border:1px solid rgba(#979797,1);
			    }
			    &:focus{
			    	box-shadow: 0 0 3px 1px $primary;
			    }
			}
			.swatch-more{
				    display: inline-block;
				    margin: 0;
				    padding: 0;
				    position: static;
				    z-index: 1;
				    line-height: 1;
				    width: 22px;
				    text-decoration: none;

				span{
					display: none;
				}

				&:before{
					content: "+";
					font-size: 18px;
					font-weight: 500;
					color: rgba(#000000,0.4);
					text-decoration: none;
				}

				&:hover:before{
					color: rgba(#000000,1);
				}
			}
		}

	}

}//.moo-products-wrapper

//Ratings
.rating-summary{
	.rating-result{
		margin-left: 0px;
		&> span:before{
		 	color: $primary;
		 }
	}
}

.product-reviews-summary{
	display: flex;
	align-items: center;
	.rating-summary{
		left:0px;
	}
}


.catalog-category-view{
	//Sidebar
	.sidebar-main{
		.block.filter{
			.filter-title{
				display: none;
			}
			.block-content.filter-content{
				strong.block-subtitle.filter-subtitle{
					display: none;
				}
			}
		}
	}
	.column.main{
		position: relative;
	}

	//main
	.page-title-wrapper{
		margin-bottom: 30px;
		padding-right: 260px;
		@include breakpoint(small down) {
			margin-bottom: 10px;
		}
		//@extend .card;
		.page-title{
			font-size: 26px;
			color: #000000;
			letter-spacing: 2px;
			text-align: left;
			line-height: 42px;
			text-transform: uppercase;
			margin-bottom: 0px;
		}
		.category-description{
			margin-bottom: 0px;
		}
	}
}
.filter-options{
	dt.filter-options-title{
		font-weight: 700;
		font-size: 16px;
		color: #000000;
		letter-spacing: 1.24px;
		text-align: left;
		text-transform: uppercase;
	}
	dd.filter-options-content{
		.items{
			.item{
				a .label,
				a{
					font-weight: 400;
					font-size: 16px;
					color: #000000;
					letter-spacing: 0.2px;
					line-height: 30px;
				}
			}
		}
	}
}

//toolbar
.toolbar.toolbar-products{
	.toolbar-amount{
		display: none;
	}
	.toolbar-sorter{
		border:1px solid #787878;
		padding:7px 10px;
		display: flex;
    	align-items: center;
    	text-transform: uppercase;
    	border-radius: 20px;

    	label{
    		font-weight: 300;
			font-size: 14px;
			color: #000000;
			letter-spacing: 1px;
			text-align: left;
    	}
    	select{
    		border:none;
    		font-weight: 700;
			font-size: 14px;
			color: #000000;
			letter-spacing: 1px;
			line-height: 18px;
			text-transform: uppercase;
			height: auto;
			background: transparent;
    		box-shadow: none;
    	}
    	.sorter-action{
    		display: none;
    	}
	}
}

//pagination
.pages{
	.items{
		display: flex;
	}
	a.page{
		width: 45px;
		height: 45px;
		border:1px solid #787878;
		font-size: 16px;
		display: flex;
	    justify-content: center;
	    align-items: center;

	    &:hover{
	    	border-color: $primary;
	    	background: $primary;
	    	
	    	span{
				color: #ffffff;
			}
	    }

		span{
			color: $primary; 
		}
	}
	strong.page{
		width: 45px;
		height: 45px;
		border:1px solid #787878;
		background:#F3F3F3;
		font-size: 16px;
		font-weight: 400;
		display: flex;
	    justify-content: center;
	    align-items: center;
	}
	.pages-item-previous,.pages-item-next{
		.action{
			width: 45px;
			height: 45px;
			background: $primary;
			color: #ffffff;
			line-height: 45px;
			border: none;
			&:visited:before,
			&:before{
				color: #ffffff;
			}
		}
	}

}
//tool-bar
.toolbar-products{
	margin-bottom: 0px;
	.toolbar-sorter.sorter{
		position: absolute; 
		top: 0px;
		right: 15px;
		@include breakpoint(small down) {
			position: static;
			display: none;//temp
		}
	}
}

//Top Filter
.catalog-topnav.amasty-catalog-topnav{
	.filter-options{
		border: none;
		background:#F8F8F8;
		padding: 0px;
	}
	.filter-subtitle {
		    display: block;
		    border-bottom: none;
		    opacity: 1;
		    font-weight: 400;
		    font-size: 15px;
		    color: #000000;
		    letter-spacing: 1.88px;
		    text-transform: uppercase;
		    padding: 0 20px;
		    line-height: 50px;
		    border-right: 1px solid rgba(#979797,0.5);
		    @include breakpoint(small down) {
		    	display: none;
		    }
		
	}
	.block-content.filter-content{
		display: flex;
		background:#F8F8F8;
		padding: 3px;
	}
	.filter-options{
		flex-grow: 1;
		display: flex;
    	justify-content: center;
    	margin-bottom: 0px;
    	text-align: center;
    	@include breakpoint(small down) {
    		position: relative;
    	}

    	.filter-options-item{
    		border-right: 1px solid rgba(#979797,0.5);
    		flex: 1 1 0;
    		padding: 0px;
    		position: relative;
    		@include breakpoint(small down) {
    			//position: static;
    		}

    		&:last-child{
    			border:none;
    			@include breakpoint(small down) {
    				 .filter-options-content{
    				 	  min-width: 200% !important;
    				 	  left: auto;
    				 	  right: 0px;
    				 	  max-height: 400px;
    						overflow-y: auto;
    						
    						.catalogsearch-result-index &{
    							min-width: 100% !important;
    						}
    				 }
    			}
    		}
    	}
    	.filter-options-title{
    		font-weight: 700;
			font-size: 15px;
			color: #000000;
			letter-spacing: 1.88px;
			line-height: 50px;
			padding: 0px;

			@include breakpoint(small down) {
		    	font-weight: 500;
				font-size: 13px;
				color: #000000;
				letter-spacing: 0.5px;
				//position: static;

				&:after{
					right: 3px;
    				top: 0px;
				}
		    }
    	}
    	.filter-options-content{
    		min-width: 100% !important;
    		border:0px;
    		background:#F8F8F8;
    		margin-top: 0px;
    		@include breakpoint(small down) {
    			//width: 94vw;
    			//transform: translateX(-50%);
    		}

 

    			.swatch-attribute.am-filter-items-attr_color{
    				 display: flex;
				    flex-wrap: wrap;
				    align-items: center;
				    .item{
				    	flex: 0 0 33.333333%;
				    	 width: 33.333333%;
				    	margin-bottom: 17px !important;
				    }
				    .am-swatch-link{
				    	position: relative;
				    	color: rgba(#000000,0.4);
				    	display: block;
				    	width: 100%;
				    	&:after{
				    		content: attr(data-label);
							font-weight: 500;
							font-size: 14px;
							color: rgba(#000000,0.4);
							letter-spacing: 0;
							text-align: center;
							line-height: 18px;
							margin-top: 3px;
							position: relative;
						    overflow: hidden;
						    width: 100%;
						    display: block;
						    white-space: nowrap;
						    text-overflow: ellipsis;
						    min-width: 66px;
				    	}
				    }
				    .swatch-option{
				    	width: 34px;
				    	height: 34px;
				    	border-radius: 50%;
				    	display: block;
					    float: none;
					    margin: 0 auto;
					    outline: none !important;

				    	&.selected{
				    		outline: none;
				    		border:3px solid #ffffff;
				    		background: #D4C1AC;
							box-shadow: 0 2px 9px 0 rgba(0,0,0,0.15);
				    	}
				    }
    			}
    		
    	}
	}
}

.catalog-topnav{
	@include breakpoint(small down) {
		display: block;
		.filter-title strong{
			display: none;
		}
	}
}
