.checkout-payment-method{
	.discount-code{
			display: block;
    		border:1px dashed $primary;
    		width: 100%;

		.payment-option-title{	
			border: none;
			padding:10px 10px 10px 44px;
			position: relative;
			font-size: 16px;
	    	color: #000000;
	    	text-transform: uppercase;
	    	.action-toggle{
	    		color: #000000;

		    	&:after{
	    			color: #000000;
	    			font-size: 20px;
	    			position: absolute;
				    right: 5px;
				    top: 16px;
	    		}
	    	}
			&:before{
    			content: "";
    			position: absolute;
    			height: 40px;
    			width: 40px;
    			background-size: 30px;
			    left: 2px;
			    top: 7px;
    			background-repeat: no-repeat;
    			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='512' viewBox='0 0 74 74' width='512'%3E%3Cpath d='m71 58h-68a1 1 0 0 1 -1-1v-10.2a1 1 0 0 1 .8-.98 9 9 0 0 0 0-17.64 1 1 0 0 1 -.8-.98v-10.2a1 1 0 0 1 1-1h68a1 1 0 0 1 1 1v10.2a1 1 0 0 1 -.8.98 9 9 0 0 0 0 17.64 1 1 0 0 1 .8.98v10.2a1 1 0 0 1 -1 1zm-67-2h66v-8.415a11 11 0 0 1 0-21.17v-8.415h-66v8.415a11 11 0 0 1 0 21.17z'/%3E%3Cpath d='m52 21.636a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.636a1 1 0 0 1 -1 1z'/%3E%3Cpath d='m52 30.727a1 1 0 0 1 -1-1v-3.636a1 1 0 1 1 2 0v3.636a1 1 0 0 1 -1 1z'/%3E%3Cpath d='m52 39.818a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.637a1 1 0 0 1 -1 .999z'/%3E%3Cpath d='m52 48.909a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.636a1 1 0 0 1 -1 1z'/%3E%3Cpath d='m52 58a1 1 0 0 1 -1-1v-3.636a1 1 0 0 1 2 0v3.636a1 1 0 0 1 -1 1z'/%3E%3Cpath d='m20.929 47.542a1 1 0 0 1 -.707-1.707l18.532-18.542a1 1 0 1 1 1.414 1.414l-18.532 18.543a1 1 0 0 1 -.707.292z'/%3E%3Cpath d='m36.381 48.019a4.619 4.619 0 1 1 4.619-4.619 4.625 4.625 0 0 1 -4.619 4.619zm0-7.239a2.619 2.619 0 1 0 2.619 2.62 2.622 2.622 0 0 0 -2.619-2.62z'/%3E%3Cpath d='m23.619 35.157a4.619 4.619 0 1 1 4.619-4.62 4.625 4.625 0 0 1 -4.619 4.62zm0-7.239a2.619 2.619 0 1 0 2.619 2.619 2.622 2.622 0 0 0 -2.619-2.619z'/%3E%3C/svg%3E");
    		}
    		
		}
		.payment-option-content{
			padding-bottom: 10px;
			padding-left: 5px;
    		padding-right: 5px;

			.payment-option-inner{
				margin-bottom: 0px;
			}
			.actions-toolbar{
				vertical-align: middle;
				@include breakpoint(small down) { 
					vertical-align: top;
				}

				.primary button.action{
					height: 53px;
					border:none;
					background:$primary;
					color: #ffffff;
					font-size: 14px;
					box-shadow: none !important;
    				margin-top: 7px;
    				@include breakpoint(small down) { 
    					margin-top: 0px;
    				}
				}
			}
		}

	}
}
.checkout-container{
	.authentication-wrapper{
		button.action-auth-toggle{
			color: #ffffff;
			text-transform: uppercase;
			background:$primary;
			padding: 5px 16px;
			font-size: 15px;
			line-height: 20px;
			text-decoration: none;
			cursor: pointer;
		}
	}
}
.cart-totals .grand .amount, 
.opc-block-summary .table-totals .grand .amount,
.cart-totals .grand .mark,
.opc-block-summary .table-totals .grand .mark{
	font-size: 18px;
	border-top: none;
}
