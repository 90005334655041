.catalog-topnav.amasty-catalog-topnav {
    //display: none;
}
//swatches
.filter-options-content{
	.swatch-attribute.size{
		.swatch-option{
				height: 45px;
				min-width: 45px;
				width: auto;
				border:1px solid #787878;
				outline: none;
				background:#ffffff;
				display: flex;
			    justify-content: center;
			    align-items: center;

			    &.text{
					font-weight: 400;
					font-size: 16px;
					color: #000000;
					letter-spacing: 0.7px;
					text-align: center;
					line-height: 20px;
					background: #ffffff;
			    }

				&:hover{
					border:1px solid #787878;
					outline: none;
				}
				&:focus{
					box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
				}
				&.selected{
					outline: none;
					background: #F3F3F3;
					border:1px solid #787878;
				}
		}
	}
}

//Price slider
.amshopby-slider-container{
	.am-slider{
		.ui-slider-handle{
			background:$primary;
		}
	}
} 
.amshopby-slider-container .am-slider .ui-slider-handle:hover, .amshopby-slider-container .am-slider .ui-slider-handle:active{
	background:$primary;
}

//Left sidebar navigation
.sidebar-main{
	#layered-filter-block{
		.block-content.filter-content{
			.block-subtitle{
				display: none;
			}
			.filter-options{
				.filter-options-item{
					border:none;

					.filter-options-title{
						font-weight: 700;
						font-size: 16px;
						color: #000000;
						letter-spacing: 1.24px;
						text-align: left;
						text-transform: uppercase;
					}
					&:first-child{
						.filter-options-title{
							display: none;
						}
						.filter-options-content{
							display: block !important;
						}
						ul.items.items-children.level-1.-folding {
							    display: block !important;
						}
					}
						
						.filter-options-content{

							ul.items.items-children.level-1.-folding {
							    padding-left: 0px;

							    > .item > a .label{
							    	font-weight: 400;
									font-size: 15px;
									color: #16161F;
									letter-spacing: 0;
							    }
							}
							.items a{
								cursor: pointer;
							}
							a:hover{
								background: none;
							}

						}
						//merker
						.items.am-filter-items-attr_merker{
							.item{
								input[type="checkbox"]{
									opacity: 0;
								}
								a{
									position: relative;
									.label{
										font-weight: 400;										
										font-size: 15px;
										color: #16161F;
										letter-spacing: 0.1px;
										line-height: 20px;
									}
									&:before{
										content: "";
										width: 20px;
										height: 20px;
										position: absolute;
										left: -20px;
										top: 2px;
										background-color: #fff;
										background-repeat: no-repeat;
										background-position: center center;
										border:1px solid #787878;
									}
									&.am_shopby_link_selected:before{
										border-color: $primary;
										background-color: $primary;
										background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' enable-background='new 0 0 10 9' viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='m10 1.6-4.7 4.9-1.5 1.7-1.6-1.7-2.2-2.3 1.6-1.7 2.2 2.4 4.6-4.9z' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E");
									}
									&:hover:before{
										border-color: $primary;
									}
								}
							}
						}

						.item{
							padding: 0px;
							a .label{
								font-size: 14px;
								color: #606060;
								letter-spacing: 0;
								line-height: 28px;
								cursor: pointer;
							}
							a.am_shopby_link_selected{
								font-weight: 600;
								.label{
									color: $primary !important;
									font-weight: 600 !important;
								}
							}
						}
						.am-labels-folding{
							.item{

								&[data-label="Kategorier"] > .am-collapse-icon{
										display: none;
								}
								&[data-label="Kategorier"] > a .label{
									font-weight: 700;
									font-size: 16px;
									color: #000000;
									letter-spacing: 1.24px;
									text-align: left;
									text-transform: uppercase;
								}
								input.am-input[type="radio"]{
									opacity: 0;
								}
								
							}
							.am-collapse-icon{
								right: 0px;
								left: auto;
								width: 12px;
								&._active:after{
									transform: rotate(0deg);
								}
								&:after{
									width: 10px;
									height: 10px;
									border:none;
									background-repeat: no-repeat;
									transform: rotate(180deg);
									background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' enable-background='new 0 0 10 7' viewBox='0 0 10 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='m1.2 6.2 3.8-3.8 3.8 3.8 1.2-1.2-5-5-5 5z' fill-rule='evenodd'/%3E%3C/svg%3E");
								}
							}
						}
						
					
				}
				.am-labels-folding .item .am-collapse-icon~[class*='am-filter-item-']{
					padding-left: 0px;
					padding-right: 17px;
				}
				.items .items-children.-folding .item{
					margin: 0
				}
			}
		}
	}
}
//current
.filter{
	.amshopby-filter-current.filter-current{
		@include breakpoint(small down) {
			    border: none;
    			margin: 0;

    			.items{
    				display: block;
    			}
		}

		.amshopby-item.item{
				border:1px solid #787878;
				padding:10px 16px 10px 24px;
				border-radius: 30px;
				margin: 4px 0;
				display: inline-flex;
	    		flex-wrap: wrap;
	    		&[data-container="price"]{
	    			.amshopby-filter-name{
	    				display: none;
	    			}
	    		}
				.amshopby-filter-value,
				.amshopby-filter-name{
					font-weight: 400;
					font-size: 14px;
					color: #000000;
					letter-spacing: 1.2px;
					line-height: 18px;
					text-transform: uppercase;
				}
				.amshopby-remove{
					left: 7px;

					&:before,
					&:after{
						height: 2px;
						background-color: #000000;
					}
				}
			}
	}
}
//Mobile filter top toggle
.amshopby-all-top-filters-append-left{
	.block-title.filter-title{
		@include breakpoint(small down) {
			strong{
				    box-shadow: none;
				    right: 0;
				    left: auto;
				    color: $primary;
				    background: none;
				    border: 1px solid $primary;
				    border-radius: 15px;
				    font-size: 13px;
				    padding: 6px;
				    top: 2px;
			}
		}
	}
}
