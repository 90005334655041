
.forhandler-landing.wrapper{
	padding-top: 0px;

	.newsletter-section{
		margin-top: 0px;
	}
}
.moo-forhandler-register-index{
	.page-title-wrapper{
		@extend .show-for-sr; 
	}
	#maincontent > .columns{
		@extend .show-for-sr; 
	}
	.sections.nav-sections{
		margin-bottom: 0px;
	}
	.page.messages{
		margin-bottom: 0px;
	}
}
//Section 01
section.top-head{
	background-repeat: no-repeat;
	background-position: top right;
	//overflow: hidden;
	.for-main-content-wrap{
		position:static;
	}
	@include breakpoint(medium only) {
		background-position: 0 120px;
		background-size: contain;
		overflow: hidden;
	}
	@include breakpoint(small down) {
		background-size: contain;
		overflow: hidden;
		background-position: 0 70px;
		padding:0 20px;
	}
	h2{
		font-weight: 400;
		font-size: 60px;
		color: #111111;
		line-height: 80px;
		letter-spacing: -0.5px;
		line-height: 74px;
		margin-top: 83px;
		display: block;
		text-transform: none;
		text-transform: uppercase;

		@include breakpoint(medium only) {
			font-size: 48px;
			line-height: 56px;
			text-align: center;
			margin-top: 44px;
			padding-left: 15px;
			padding-right: 15px;
			margin-top: 0px;
		}
		@include breakpoint(small down) {
			font-size: 24px;
			line-height: 36px;
			text-align: center;
			margin-top: 0px;
			margin-bottom: 27px;
		}
	}

	.content-wrap{
		margin-top: 30px;
		@include breakpoint(small down) {
			text-align: center;
		}
		p{
			font-size: 17px;
			color: rgba(#111111, 0.8);
			letter-spacing: 0.19px;
			line-height: 30px;

			&:last-child{
				margin-bottom: 0px;
			}
		}
		@include breakpoint(medium only) {
			background: #ffffff;
			position:relative;
			padding-top:36px;
			padding-left: 60px;
			padding-right: 60px;
			text-align: center;

			&:before{
				content: "";
				width: 100%;
				height: 115px;
				position:absolute;
				left: 0px;
				top: -114px;
				background: #fff;
			}
			&:after{
				content: "";
				width: 15px;
				height: 100%;
				position:absolute;
				right: -15px;
				top: 0;
				background: #F9F9F9;
			}
		}
	}
	.payment-wrap{
		margin-top: 53px;
		position:relative;

		//temp as client requirement 
		@include breakpoint(large up) {
			opacity: 0;
		}
		@include breakpoint(medium down) {
			display: none;
		}

		.row{
			align-items: center;
			justify-content: center;

			&> div{
				height:84px;
				display: flex;
				justify-content: center;
			}
			@include breakpoint(small down) {
				img{
					    max-width: 84px;
    					max-height: 44px;
				}
			}
		}
		@include breakpoint(medium down) {
			margin-top: 0;
			padding-top:28px;
			.row > div{
				height: 70px;
			}
		}

	}
}

//Section 02
section.pro-section {
    margin-top: 280px;
    text-align: center;

    .user-logged-in &{
    	margin-top: 120px;
    }

    .container{
    	max-width: 900px;
    }
    @include breakpoint(medium only) {
    	margin-top: 70px !important;
    }
    @include breakpoint(small down) {
    	margin-top: 56px !important;
    }

    h2{
		font-size: 40px;
		color: #111111;
		text-align: center;
    	font-weight: 700;
		text-align: center;
		line-height: 56px;
		text-transform: none;
		margin-bottom: 68px;
		text-transform: none;

	    @include breakpoint(small down) {
	    	font-size: 26px;
	    	line-height: 36px;
	    	margin-bottom: 24px;
	    }
    }
    h3{
    	font-weight: 700;
		font-size: 24px;
		color: #111111;
		text-align: center;
		line-height: 32px;
		text-transform: uppercase;
		margin-bottom: 18px;

		@include breakpoint(medium only) {
	    	font-size: 21px;
	    	line-height: 30px;
	    }
	    @include breakpoint(small down) {
	    	font-size: 20px;
	    	line-height: 30px;
	    	margin-bottom: 0px;
	    }
    }
    .product-image-box h3{
    	text-transform: none;
    }
    .product-image-box{
    	 > div{
    	 	margin-bottom: 30px;
    	 }
    	 .product-image-box-item{
    	 	 @include breakpoint(small down) {
    	 	 	&:nth-child(3n){
    	 	 		flex: 0 0 100%;
					max-width: 100%;
					margin-bottom: 10px;
    	 	 	}
    	 	 }

    	 	 .product-image-box-image{
    	 	 	display: block;
    	 	 	img{
    	 	 		width: 100%;
    	 	 		height: auto;
    	 	 		display: block;
    	 	 	}
    	 	 }
    	 }
    	 .moo-content{
    	 	padding-top: 30px;
    	 	display: block;
    		width: 100%;

    	 	p{
    	 		font-size: 16px;
    	 		line-height: 32px;
    	 		font-weight: 600;
    	 		color: rgba(#000000,0.6);

    	 		 @include breakpoint(small down) {
    	 		 	font-size: 15px;
    	 		 }
    	 	}
    	 }
    	 @include breakpoint(small down) {
    	 	max-width: 262px;
		    display: block;
		    margin: 0 auto;
    	 }
    }
    .foot{
    	margin-top: 1rem;
    	padding: 0 20px;
    	@include breakpoint(small down) {
    		margin-top: 0px;
    	}

    	p{
			
			font-weight: 300;
			font-size: 17px;
			color: #000000;
			letter-spacing: 0.19px;
			text-align: center;
			line-height: 30px;
			margin-bottom: 30px;

			@include breakpoint(small down) {
				font-size: 16px;
				line-height: 26px;
			}
    	}
    	.button{
    		font-size: 18px;
			min-width: 200px;
			line-height: 40px;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 1px;
			color: #ffffff;
    	}
    }
}

//Section 03
section.video-section{
	margin-top: 97px;
	margin-bottom: 50px;
		@include breakpoint(medium only) {
				margin-top: 80px;
				margin-bottom: 80px;
			}
			@include breakpoint(small down) {
				margin-top: 70px;
				margin-bottom: 30px;
			}

	.video-cover-wrap{
		.wide-video-box{
			display: flex;
			width: 100%;
			height: 490px;
			background-repeat:no-repeat;
			background-size: cover;
			justify-content: center;
			align-items:center;
			position:relative;
			flex-direction: column;
			text-decoration: none !important;
			background-position: center;

			@include breakpoint(medium only) {
				height: 490px;
			}
			@include breakpoint(small down) {
				height: 375px;
			}

			h2{
				font-weight:400;
				font-size: 44px;
				color: #FFFFFF;
				letter-spacing: 1;
				text-align: center;
				line-height: 50px;
				text-transform: none;
				position:relative;
				z-index: 2;
				margin-bottom: 36px;

				@include breakpoint(medium only) {
					font-size: 44px;
					margin-bottom: 50px;
				}
				@include breakpoint(small down) {
					font-size: 34px;
				}
			}
			.moo-play-icon{
				position:relative;
				z-index: 2;

				svg{
					max-width: 100%;
					height: auto;
				}

				@include breakpoint(medium only) {
					width: 80px;
				}
				@include breakpoint(small down) {
					width: 60px;
				}
			}
			.video-overlay{
				position:absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background:#2D2E30;
				opacity: 0.54;
				z-index: 0;
			}

			&:hover{
				.video-overlay{
					opacity: 0.40;
				}
			}
		}
	}

	.dark-section{
		padding-top:80px;
		padding-bottom:80px;
		background:#000000;
		color: #ffffff;


			@include breakpoint(small down) {
				padding-top:50px;
				padding-bottom:50px;
			}

		h2{
			text-transform: none;
			display: block;
			text-align: center;
			font-weight: 800;
			font-size: 44px;
			color: #FFFFFF;
			letter-spacing: -0.34px;
			line-height: 74px;
			margin-bottom: 24px;

			@include breakpoint(medium only) {
				font-size: 35px;
				line-height: 48px
			}

			@include breakpoint(small down) {
				font-size: 24px;
				line-height: 33px;
			}
		}

		.row{
			align-items:center;
		}
		.pro-list-item{
			margin-bottom: 58px;
			position:relative;
			padding-left:64px;
			@include breakpoint(medium only) {
				margin-bottom: 26px;
				padding-left:50px;

				&:after{
					width: 35px !important;
					height: 35px !important;
				}
			}
			@include breakpoint(small down) {
				margin-bottom: 21px;
				padding-left:45px;

				&:after{
					width: 30px !important;
					height: 30px !important;
				}
			}


			&:after{
				content: "";
				background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' enable-background='new 0 0 44 44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='22' cy='22' fill='none' r='21.5' stroke='%23f89b00'/%3E%3Ccircle cx='22' cy='22' fill='%23f89b00' r='18.2'/%3E%3Cpath d='m14.5 10.7 18.2 11.7-18.2 11.8 5-11.8z' fill='%23fff'/%3E%3C/svg%3E");
				width: 44px;
				height: 44px;
				background-repeat:no-repeat;
				background-size:contain;
				background-position:center;
				left: 0px;
				top: 0;
				display: block;
				position: absolute;
			}
			@include breakpoint(large up) {

				&.list-item-01,&.list-item-02{
					padding-left:0px;
					padding-right:64px;
					text-align: right;

					&:after{
						right: 0;
						left: auto;
					}
				}

			}

			h3{
				font-weight: 600;
				font-size: 20px;
				color: #FFFFFF;
				letter-spacing: 0;
				line-height: 30px;
				margin-bottom: 12px;

				@include breakpoint(medium only) {
					font-size: 19px;
					margin-bottom: 7px;

				}
				@include breakpoint(small down) {
					font-size: 18px;
					margin-bottom: 5px;

				}
			}
			p{
				font-size: 16px;
				color: rgba(#ffffff, 0.8);
				letter-spacing: 0;
				line-height: 25px;
				margin-bottom: 0px;

				@include breakpoint(small down) {
					font-size: 15px;
					line-height: 24px;

				}
			}

			&.list-item-04{
				margin-bottom: 0px;
			}
			@include breakpoint(large up) {
				&.list-item-02{
					margin-bottom: 0px;
				}
			}
		}
		.col-item-03 .pro-list-item:after{
			transform: rotate(-180deg);
		}
	}
}

//Section Two banner
section.moo-two-banner{
	background-repeat: no-repeat;
	background-position: right top;
	margin-bottom: 110px;
	background-size: 600px;

	@include breakpoint(medium only) {
		margin-bottom: 80px;
		background-size: 448px;
		background-position: 135% top;
	}
	@include breakpoint(small down) {
		background-image: none !important;
		margin-bottom: 0px;
	}

	.top-wrap{
		padding-top: 58px;
		padding-bottom: 132px;
		@include breakpoint(medium only) {
			padding-top: 0px;
			padding-bottom: 50px;
		}
		@include breakpoint(small down) {
			padding-top: 0px;
			padding-bottom: 0px;
			.lists-wrap{
				margin-top: 51px;
				padding-left: 24px;
				padding-right: 24px;
			}
		}
	}
	.below-wrap{
		background-repeat: no-repeat;
		background-position: left;
		padding-top: 116px;
    	padding-bottom: 60px;
    	@include breakpoint(medium only) {
    		background-repeat: no-repeat;
		    padding-top: 10px;
		    background-size: 500px;
		    background-position: -60% 0;
    	}
    	@include breakpoint(small down) {
			background-image: none !important;
			padding-top: 64px;
			padding-bottom: 47px;

			.columns{
				padding-top: 40px;
				padding-left: 24px;
				padding-right: 24px;
			}
		}

    	h2{
    		margin-bottom: 40px;

    		@include breakpoint(medium only) {
    			line-height: 50px;
    		}
    		@include breakpoint(small down) {
    			font-size: 26px;
    			line-height: 30px;
    			margin-bottom: 20px;
    		}
    	}
    	p{
    		font-weight: 300;
			font-size: 17px;
			color: #000000;
			letter-spacing: 0;
			line-height: 30px;
			@include breakpoint(small down) {
				font-size: 16px;
			}
    	}
	}
	h2{
		font-weight: 400;
		font-size: 44px;
		color: #000000;
		letter-spacing: -0.34px;
		line-height: 74px;

		@include breakpoint(medium only) {
			font-size: 36px;
			letter-spacing: -0.28px;
			line-height: 74px;
		}
		@include breakpoint(small down) {
			font-size: 28px;
			letter-spacing: -0.22px;
			line-height: 43px;
		}
	}
	h3{
		font-weight: 700;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		line-height: 30px;
		margin-bottom: 10px;

		@include breakpoint(medium down) {
			font-size: 18px;
			line-height: 30px;
		}
	}
	
	.moo-pop-list{
		margin-left: 0px;
		margin-top: 44px;
		@include breakpoint(small down) {
			margin-top: 20px;
		}

		li{
			position: relative;
			padding-left: 70px;
			list-style: none;
			display: block;
			margin-bottom: 30px;
			@include breakpoint(small down) {
				padding-left: 52px
			}

			&:before{
				content: "";
				position: absolute;
				width: 49px;
				height: 49px;
				left: 0;
				top: 0;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 49 49' viewBox='0 0 49 49' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cfilter id='a' height='273.5%25' width='273.5%25' x='-86.7%25' y='-62.2%25'%3E%3CfeMorphology in='SourceAlpha' operator='erode' radius='2' result='shadowSpreadOuter1'/%3E%3CfeOffset dx='0' dy='12' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeMorphology in='SourceAlpha' operator='dilate' radius='1' result='shadowInner'/%3E%3CfeOffset dx='0' dy='12' in='shadowInner' result='shadowInner'/%3E%3CfeComposite in='shadowOffsetOuter1' in2='shadowInner' operator='out' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='12.5'/%3E%3CfeColorMatrix in='shadowBlurOuter1' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0'/%3E%3C/filter%3E%3Cg filter='url(%23a)'%3E%3Ccircle clip-rule='evenodd' cx='24.5' cy='24.5' fill-rule='evenodd' r='24.5'/%3E%3C/g%3E%3Ccircle cx='24.5' cy='24.5' fill='none' r='24' stroke='%23000'/%3E%3Cg clip-rule='evenodd' fill-rule='evenodd'%3E%3Ccircle cx='24.5' cy='24.5' r='20.7'/%3E%3Cpath d='m18.5 13.7 16.2 10.4-16.2 10.5 4.5-10.5z' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
				
				@include breakpoint(small down) {
					width: 40px;
					height: 40px;
				}
			}
			p{
				font-weight: 300;
				font-size: 16px;
				color: #000000;
				letter-spacing: 0;
				line-height: 25px;

				@include breakpoint(small down) {
					font-size: 14px;
				}
			}
		}
	}
}
//Story section
section.story-forhandler{
	.story-section{
		padding: 120px 0;
		//border-top: 1px solid #787878;
		//border-bottom: 1px solid #787878;

		.row{
			align-items: center;
		}
	}
	.image-wrap{
		@include breakpoint(medium down) {
			text-align: center;
			margin-bottom: 50px;
		}
		@include breakpoint(small down) {
			overflow: hidden;
		}
	}
	.img-holder{
		position: relative;
		display: inline-block;
		border: 1px solid #D0D0D0;
		box-shadow: 0 12px 24px -6px rgba(0,0,0,0.12);

		@include breakpoint(small down) {
			width: 280px;
			max-width: 100%;
		}

		.icon-holder{
			position: absolute;
			right: -45px;
    		top: 40px;

    		@include breakpoint(small down) {
    			right:-28px;
    			top: 28px;

	    		svg{
	    			width: 56px;
	    			height: 56px;
	    		}
	    	}
		}
	}
	.content-wrap{
		padding-left: 50px;
		@include breakpoint(small down) {
			padding-left: 15px;
			padding-right: 15px;
		}
		h2{
			font-weight: 400;
			font-size: 32px;
			color: #000000;
			line-height: 46px;
			margin-bottom: 27px;

			@include breakpoint(small down) {
				font-size: 26px;
				line-height: 36px;
				margin-bottom: 20px;
			}
		}
		p{
			opacity: 0.8;
			font-weight: 400;
			font-size: 16px;
			color: #000000;
			letter-spacing: 0;
			line-height: 30px;

			@include breakpoint(small down) {
				line-height: 26px;
			}
		}
		.button{
			font-weight: 700;
			font-size: 17px;
			color: #FFFFFF;
			letter-spacing: 1px;
			text-align: center;
			min-width: 200px;
			margin-top:54px;
			line-height:40px;
			text-transform: uppercase;
		}
	}
}
//Section faq
section.faq-section{

	padding:80px 60px;

		@include breakpoint(medium only) {
			padding:60px;
		}
		@include breakpoint(small down) {
			padding:50px 20px;
		}

	h2{
		font-weight:400;
		font-size: 44px;
		color: #111111;
		letter-spacing: -0.34px;
		text-align: center;
		line-height: 74px;
		margin-bottom: 60px;
		display: block;
		text-transform: none;
		@include breakpoint(medium only) {
			font-size: 35px;
			line-height: 48px;
			margin-bottom: 50px;
			
		}
		@include breakpoint(small down) {
			font-size: 24px;
			line-height: 33px;
			margin-bottom: 16px;
		}
	}
	.faq-product{
		max-width: 860px;
		margin: 0 auto;

		.accordion-item{
			border-bottom: 1px solid #787878;
			margin-bottom: 0px;
			border-left: 0px;
			border-right: 0px;
			&:first-child{
				border-top: 1px solid #787878;
			}

			.accordion-title{
				font-weight: 400;
				font-size: 22px;
				color: #111111;
				letter-spacing: 0.24px;
				line-height: 30px;
				padding-top:30px;
				padding-bottom:30px;
				background:none;
				text-decoration: none;
				border: 0px;
				&:hover{
					background:none;
					color: #111111;
					text-decoration: none;
				}

				@include breakpoint(medium only) {
					font-size: 20px;
					line-height: 30px;
				}
				@include breakpoint(small down) {
					font-size: 17px;
					line-height: 30px;
					padding-top:12px;
					padding-bottom:12px;
				}

				.cross{
					border:none;

					&:before,&:after{
						background-color: #111111;
					}
				}
			}

			.accordion-content{
				font-size: 18px;
				color: rgba(#111111, 0.8);
				letter-spacing: 0.3px;
				line-height: 26px;
				margin-bottom: 30px;
				border:none;
				@include breakpoint(small down) {
					font-size: 16px;
					line-height: 23px;
				}
			}
		}
	}
}
// Register Form Start
.customer-account-login.moo-forhandler-register-index,
.customer-account-create.moo-forhandler-register-index{
	.login-container{


		.block.block-customer-login{
			padding: 0px;
			border:none;
			width: 100%;
			margin-bottom: 0px;

			.block-content {
				fieldset.fieldset.login{
					&:after{
						display: none;
					}
				}
				.actions-toolbar .primary button{
					margin-right: 0px;
				}
			}

			.block-title{
				margin-bottom: 0px;
				border: none;
    			padding-bottom: 0;
    			margin-top: 24px;

				strong{
					margin-left: 0 !important;
				    border: none;
				    margin-bottom: 20px;
				    padding-bottom: 0px;
				    font-weight: 400;
					font-size: 24px;
					color: $primary;
					letter-spacing: 0;
					line-height: 25px;
				}
			}
		}
		> .block.block-new-customer{
			display: none;
		}
	}

	form.form.form-login,
	form.form-create-account{
		display: flex;
	    flex-wrap: wrap;
	    justify-content: space-between;
	    width: 100% !important;
	    flex-direction:column;
	    min-width: 20px;
	    margin-top: 30px;
	    fieldset.fieldset.login,
	    fieldset.fieldset.address,
	    fieldset.fieldset.create.account,
	    fieldset.fieldset.create.info{
	    	    width: 100%;
			    margin-bottom: 0px;
			    border:none;
			    padding: 0px;
			    border: none;
			    @include breakpoint(small down) {
			    	width: 100%;
			     }
			    legend.legend {
				    margin-left: 0 !important;
				    border: none;
				    margin-bottom: 20px;
				    padding-bottom: 0px;
					font-weight: 600;
					font-size: 16px;
					color: #000000;
					letter-spacing: 5px;
					line-height: 25px;
					text-transform: uppercase;

					span{
						font-weight: 600;
					}
				}
				.field {
					position: relative;

					label.label {
					    background: none;
					    color: rgba(#000000,0.54);
					    padding: 0;
					    font-size: 1rem;
					    font-weight: 300;
					}
					.control{
						input {
						    background: #fff;
						    border: 1px solid #ACACAC;
						    height: 52px;
						    line-height: 24px;
						    padding: 12px 15px;
						    vertical-align: baseline;
						    width: 100%;
							font-weight: 300;
							font-size: 17px;
							color: #000000;
							letter-spacing: 0;
							box-shadow: none;

						    &:focus{
						    	box-shadow: none;
						    	border: 1px solid $primary;
						    }
						}
					}
				}
				div.mage-error[generated]{
					font-size: 14px;
				}
	    }
	    fieldset.fieldset.create.info{
	    	.field:not(.newsletter){
				@extend .column;
				@extend .medium-6;
				@extend .small-12;
			}
			.field.newsletter{
				@extend .column;
				@extend .small-12;
			}
	    }

	    fieldset.fieldset.create.account > legend.legend,
	    fieldset.fieldset.address > legend.legend{
	    	display: none;
	    }


		.actions-toolbar {
		    width: 100%;
		    margin-left: 0 !important;
		    justify-content: center;
		    align-items: center;
		    text-align: center;
		    display: flex;
		    margin-top: 20px;
    		flex-direction: column;

    		.primary{
		    		width: 100%;
					justify-content: center;
					text-align: center;
					align-items: center;
					 display: flex;

		    		button.action.submit.primary {
					    width: 100%;
					    font-size: 1.1rem;
					    padding-top: 20px;
					    padding-bottom: 20px;
					    text-transform: uppercase;
					}
		    	}
		}
	}
	form.form-create-account{
		fieldset.fieldset.create.account{
			.field .control input{
				width: 93%;
			}
			&:after{
				display: none;
			}
		}
		fieldset.fieldset.aw-gdpr-consent{
			.field.choice {
			    display: flex;
			    flex-direction: row;

			    label.label {
				    white-space: inherit;
				    padding-left: 8px;
				}
			}
		}
	}
}
// Register Form Ends


//form
.forhandler-form{
	position:absolute;
	width: 485px;
	//height: 547px;
	max-width: 100%;
	background: #FFFFFF;
	box-shadow: 0 70px 100px -30px rgba(0,0,0,0.15);
	right: 20%;
	z-index: 2;

	&.login-active{
		#show-more-form{
			display: none;
		}
	}
	@include breakpoint(xlarge only) {
		right: 20%;
	}
	@include breakpoint(large only) {
		right: 10%;
	}
	@include breakpoint(medium only) {
		position:relative;
		margin:0 auto;
		width: 100%;
		max-width: 485px;
		right: auto;
		margin-top: 200px;
	}
	@include breakpoint(small down) {
		position:static;
		margin:0 auto;
		width: 100%;
		right: auto;
		margin-top: 200px;
	}
	.lost_password{
		a{
			float: right;
		}
	}

	.tabs-content{
		padding: 0px;
		border:none;
		.tabs-panel{
			padding: 0px;
		}
	}
	ul.tabs{
		display: flex;
		border:none;
		li.tabs-title{
			float: none;
		    flex-grow: 1;
		    text-align: center;
		    margin-bottom: 0px;

		    a{
				font-weight:700;
				font-size: 16px;
				color: rgba(#FFFFFF, 0.5);
				letter-spacing: 0.94px;
				text-align: center;
				background:#2C3033;
				padding-top: 19px;
				padding-bottom: 19px;
				border:0px !important;
				box-shadow: 0 70px 100px -30px rgba(0,0,0,0.15);
				margin-bottom: 0px;
				text-decoration: none;

				&:hover{
					background:rgba(#2C3033,0.9);
				}

				@include breakpoint(small down) {
					font-size: 14px;
					line-height: 19px;
					padding: 20px 10px;
				}
			}
			&.is-active a{
				color: #ffffff;
				background:#111111;
				&:hover{
					background:rgba(#111111,0.9);
				}
			}
		}
	}

	//user loggedin
	&.user-is-loggedin{
		.forhanler-head{
			display: none;
		}
		.forhandler-form-inner{
			
			.woocommerce{
				h2{
					width: 100%;
					background: #111;
					color: #ffffff;
					font-weight: 700;
			    	font-size: 16px;
			    	text-align: center;
			        padding-top: 19px;
			    	padding-bottom: 19px;
			        letter-spacing: .94px;
			    	text-align: center;
			    	display: block !important;
				}
				.woocommerce-MyAccount-navigation {
					.list-group{
						padding: 30px 0 40px 0;

						.list-group-item{
							border:none;
							font-size: 14px;
							color: #111111;
							font-weight: 400;
							text-transform: uppercase;
							position:relative;
							word-break: break-all;
							border-radius:0px !important;
							padding:1.4rem 1.25rem 1.4rem 96px;

							@include breakpoint(medium only) {
								padding:50px 10px 10px 10px;
								font-size: 13px;
								text-align: center;
								border-bottom:4px solid transparent;
							}
							@include breakpoint(small down) {
								padding:45px 5px 0 5px;
								font-size: 10.4px;
								text-align: center;
								border-bottom:4px solid transparent;
							}

							&:hover{
								color: $primary;
							}

							&:before{
								content: "";
								position:absolute;
								width: 20px;
								height: 20px;
								background-size:contain;
								background-repeat:no-repeat;
								left: 50px;
								opacity: 0.6;
								background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 15 15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23121212' stroke-width='.8'%3E%3Cpath d='m1.4.4h3.6c.6 0 1 .4 1 1v3.6c0 .6-.4 1-1 1h-3.6c-.6 0-1-.4-1-1v-3.6c0-.6.4-1 1-1z'/%3E%3Cpath d='m10 .4h3.6c.6 0 1 .4 1 1v3.6c0 .6-.4 1-1 1h-3.6c-.6 0-1-.4-1-1v-3.6c0-.6.4-1 1-1z'/%3E%3Cpath d='m1.4 9h3.6c.6 0 1 .4 1 1v3.6c0 .6-.4 1-1 1h-3.6c-.6 0-1-.4-1-1v-3.6c0-.6.4-1 1-1z'/%3E%3Cpath d='m10 9h3.6c.6 0 1 .4 1 1v3.6c0 .6-.4 1-1 1h-3.6c-.6 0-1-.4-1-1v-3.6c0-.6.4-1 1-1z'/%3E%3C/g%3E%3C/svg%3E");
								
								@include breakpoint(medium down) {
									top: 20px;
									left: 50%;
									margin-left:-10px;
								}
							}
							&#orders:before{
									background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 21 18' viewBox='0 0 21 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m10.2 16.3c0 .9-.7 1.7-1.6 1.7s-1.6-.8-1.6-1.7.7-1.7 1.6-1.7 1.6.8 1.6 1.7zm-2.7 0c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1-.5-1.1-1.1-1.1-1.1.5-1.1 1.1zm9 1.7c-.9 0-1.6-.8-1.6-1.7s.7-1.7 1.6-1.7 1.6.8 1.6 1.7-.7 1.7-1.6 1.7zm0-2.8c-.6 0-1.1.5-1.1 1.1s.5 1.1 1.1 1.1 1.1-.5 1.1-1.1-.5-1.1-1.1-1.1zm-16.1-14.5c-.2 0-.4-.1-.4-.3s.2-.4.4-.4h2.2c1.1 0 2 .8 2.1 1.9l.2 1.3h15.8c.1 0 .2.1.3.2v.2l-2 5.9c-.3.9-1.1 1.5-2 1.5h-10.9l.2 1c.3.8.8 1.1 1.5 1.1h10.6c.2 0 .4.1.4.3s-.2.4-.4.4h-10.6c-1.1 0-2-.8-2.1-1.9l-1.8-9.9c-.1-.7-.7-1.3-1.4-1.3zm19.6 3.1h-15l1 6.5h10.8c.6 0 1.2-.4 1.4-1zm-9.5-.4c.2 0 .3.1.3.3v6.5c0 .2-.1.3-.3.3s-.3-.1-.3-.3v-6.5c0-.2.2-.3.3-.3zm4.8 0c.2 0 .3.1.3.3v6.5c0 .2-.1.3-.3.3s-.3-.1-.3-.3v-6.5c.1-.2.2-.3.3-.3zm-9.9 3.3h13.4c.2 0 .3.1.3.3s-.1.3-.3.3h-13.4c-.2 0-.3-.1-.3-.3s.1-.3.3-.3z' fill='%23121212'/%3E%3C/svg%3E");
							}
							&#downloads{
								display: none;
							}
							&#edit-address:before{
								background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 18 17' viewBox='0 0 18 17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23121212'%3E%3Cpath d='m17.8 6.4-8.5-6.3c-.2-.1-.4-.1-.6 0l-8.5 6.3c-.2.1-.2.4-.1.6.2.2.4.3.7.1l8.2-6 8.2 6c.1.1.2.1.3.1s.3-.1.4-.2c.2-.2.1-.5-.1-.6z'/%3E%3Cpath d='m15.6 7.2c-.3 0-.5.2-.5.5v7.4h-3.7v-4.1c0-1.3-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v4.1h-3.9v-7.4c0-.3-.2-.5-.5-.5s-.4.2-.4.5v7.9c0 .2.2.4.5.4h4.7c.2 0 .4-.2.5-.4v-4.5c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4v4.5c0 .2.2.4.5.4h4.7c.3 0 .5-.2.5-.5v-7.8c-.2-.3-.4-.5-.6-.5z'/%3E%3C/g%3E%3C/svg%3E");
							}
							&#edit-account:before{
								background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 15 15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m12.8 9.7c-.8-.8-1.8-1.4-2.8-1.8 1.1-.8 1.9-2.1 1.9-3.6-.1-2.4-2-4.3-4.4-4.3s-4.3 1.9-4.3 4.3c0 1.5.7 2.8 1.9 3.6-1.1.4-2.1 1-2.9 1.8-1.4 1.4-2.2 3.3-2.2 5.3h1.2c0-3.5 2.8-6.3 6.3-6.3s6.3 2.8 6.3 6.3h1.2c0-2-.8-3.9-2.2-5.3zm-5.3-2.2c-1.7 0-3.2-1.4-3.2-3.2s1.4-3.2 3.2-3.2 3.2 1.4 3.2 3.2-1.5 3.2-3.2 3.2z' fill='%23121212'/%3E%3C/svg%3E");
							}
							&#tinv_wishlist:before{
								background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 21 18' viewBox='0 0 21 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%231c1c1c'%3E%3Cpath d='m19.4 1.8c-1.1-1.2-2.6-1.8-4.2-1.8s-3 .6-4.1 1.7l-.6.6-.6-.6c-1.1-1.1-2.5-1.7-4.1-1.7s-3 .6-4.1 1.7-1.7 2.6-1.7 4.1.6 3 1.7 4.1l7.3 7.3c.4.4 1 .6 1.5.6.6 0 1.1-.2 1.5-.6l7.2-7.2c2.3-2.3 2.4-6 .2-8.2zm-.9 7.4-7.2 7.2c-.4.4-1.1.4-1.5 0l-7.3-7.2c-.9-.9-1.4-2.1-1.4-3.4s.5-2.5 1.4-3.4 2.1-1.4 3.4-1.4 2.5.5 3.4 1.4l.4.6-.8.9c-.2.2-.2.6 0 .8.1.1.2.1.4.1.1 0 .3-.1.4-.2l2.2-2.2c.9-.9 2.1-1.4 3.4-1.4s2.5.5 3.4 1.4c1.7 2 1.7 5-.2 6.8z'/%3E%3Cpath d='m19.2 5.7c-.1-1-.5-1.9-1.2-2.6-.5-.5-1.1-.8-1.8-1-.3-.1-.6.1-.7.4s.1.6.4.7c.5.1.9.4 1.3.7.5.5.8 1.2.8 1.9 0 .3.3.5.6.5s.6-.3.6-.6z'/%3E%3C/g%3E%3C/svg%3E");
							}
							&#customer-logout:before{
								background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 20 19' viewBox='0 0 20 19' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23121212'%3E%3Cpath d='m14.6 8.6h-12.9l2.8-3.2c.2-.2.2-.6-.1-.8s-.6-.2-.8 0l-3.5 4c0 .2-.1.4-.1.5 0 .2.1.3.2.4l3.5 3.5c.2.2.6.2.8 0s.2-.6 0-.8l-2.6-2.5h12.8c.3 0 .5-.2.5-.5-.1-.4-.3-.6-.6-.6z'/%3E%3Cpath d='m17.8 0h-7.2c-1.2 0-2.2 1-2.2 2.1v1.9c0 .3.2.5.5.5s.5-.2.5-.5v-1.9c0-.6.5-1.1 1.1-1.1h7.2c.6 0 1.1.5 1.1 1v13.9c0 .6-.5 1.1-1 1.1h-7.3c-.6 0-1.1-.5-1.1-1.1v-1.9c0-.3-.2-.5-.5-.5s-.5.2-.5.5v1.9c0 1.2 1 2.1 2.2 2.1h7.2c1.2 0 2.1-.9 2.1-2.1v-14c.1-.9-.9-1.9-2.1-1.9z'/%3E%3C/g%3E%3C/svg%3E");
							}
						}
					}
					h2{
						display: none !important;
					}
				}

				.woocommerce-MyAccount-content{
					display: none;
				}
			}
		}
	}

	.forhanler-head{
		.button-group{
			width: 100%;

			button{
				font-weight:700;
				font-size: 16px;
				color: rgba(#FFFFFF, 0.5);
				letter-spacing: 0.94px;
				text-align: center;
				background:#2C3033;
				padding-top: 19px;
				padding-bottom: 19px;
				border:0px !important;
				box-shadow: 0 70px 100px -30px rgba(0,0,0,0.15);

				&.active{
					color: #ffffff;
					background:#111111;
				}
				@include breakpoint(small down) {
					font-size: 14px;
					line-height: 19px;
					padding: 20px 10px;
				}
			}
		}
	}
	.forhandler-form-inner{
		padding:0 30px 30px 30px;
    	margin-top: 0px;
    	
		.moo-forhandler-form{
			max-height: 400px;
		    overflow-y: scroll;
		    overflow-x: hidden;
		    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
		    background-repeat: no-repeat;
		    background-color: white;
		    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
		    background-attachment: local, local, scroll, scroll;
			.fieldset{
				margin: 0px;
				padding: 0px;
			}
			.forhandler-signup{
				.field.choice.newsletter {
				    margin-bottom: 10px;
				}
			}
		}
		.moo-forhandler-login-wrap{
			height: auto;
			margin-bottom: 0px;
			overflow: hidden;

			form.form.form-login{
				margin-top: 0;
			}
		}
	}

	.woocommerce-form-login,.woocommerce-form-register{
		display: none;
	}

	&.signup-active .woocommerce-form-register{
		display: block;
	}
	&.login-active .woocommerce-form-login{
		display: block;
	}

	.woocommerce-form.woocommerce-form-login,
	.woocommerce-form.woocommerce-form-register{
		h2{
			font-weight: 700;
			font-size: 24px;
			color: #1A1E22;
			letter-spacing: 0;
			line-height: 25px;
			margin-bottom: 22px;
			text-transform: none;
			display: block;
			clear: both;
			@include breakpoint(small down) {
				font-size: 18px;
				margin-bottom: 14px;
			}
		}
		input[type="text"],
		input[type="password"],
		input[type="number"],
		input[type="tel"]{
			height: 58px;
			border-color:#BFC1C7;
			border-radius:1px;
			font-weight: 400;
			font-size: 17px;
			color: #111111;
			letter-spacing: 0;
			
		}
		input[type="email"]{
			height: 58px;
			border-color:#BFC1C7;
			border-radius:1px;
			font-weight: 400;
			font-size: 17px;
			color: #111111;
			letter-spacing: 0;
		}
		.form-row{
			margin-bottom: 10px;
			label{
				display: none;
			}
			input{
				&::placeholder{
					color: #111111 !important;
					opacity: 0.54 !important;
				}
			}

			&#billing_country_field{
				label{
					display: inline-block;
				}
				.woocommerce-input-wrapper{
					display: inline-block;
					width: auto;
					line-height: 32px;
					margin-left: 10px;
				}
			}
			&#billing_phone_field{
				width: 100%;
				float: none;
			}
			&#billing_city_field{
				float: right;
			}
			.btn{
				width: 100%;
				font-weight: 500;
				font-size: 16px;
				color: #FFFFFF;
				letter-spacing: 1px;
				text-align: center;
				height: 60px;
				text-transform: uppercase;
				border-radius:0px;
			}
		}
		.woocommerce-privacy-policy-text p{
			color: rgba(#111111, 0.8);
		}
	}
	#wcb2b_handler_type_field{
			#wcb2b_handler_type{
				border-radius: 1px;
				height: 58px;
			}
		}

		.sho-more{
			display: none;
			position:relative;
			text-align: center;
			cursor: pointer;
			height: 40px;

			&:hover{
				color: $primary;
				#more-arrows {
					polygon {
				      fill: $primary;
				      transition: all .2s ease-out;

				      &.arrow-bottom {
				        transform: translateY(-18px);
				      }

				      &.arrow-top {
				        transform: translateY(18px);
				      }
				      
				    }
				}

			}

			span{
				display: block;
			}
			.showless{
				display: none;
			}

			&.active{
				.showless{
					display: block;
				}
				.showmore{
					display: none;
				}
				#more-arrows{
					transform: rotate(180deg) scale(.5);
				}
			}

			/* Arrow & Hover Animation */
			#more-arrows {
			  width: 75px;
			  height: 65px;
			  transform: scale(0.5);
			  margin-top: -10px;

			  polygon {
				  fill: $primary;
				  transition: all .2s ease-out;
				    
				  &.arrow-middle {
				      opacity: 0.75;
				    }

				    &.arrow-top {
				      opacity: 0.5
				    }
				     
				}
			  
			}

			
		}

		&.signup-active .sho-more{
			display: block;
		}
}


.login-container{
	.form-create-account .fieldset:after{
		display: none;
	}
	.form-create-account .fieldset>.field>.control{
		width: 100%;
	}
}

//product-per-boxes
.box-product-info{
	background: rgba(#D8D8D8,0.15);
	padding:16px 20px;

	.box-product-item{
		display: flex;
		align-items: center;

		.box-icon {
		    //width: 38px;
		     svg{
		     	display: block;
		     }
		}
		.box-detail{
			font-weight: 400;
			font-size: 15px;
			color: #000000;
			letter-spacing: 0;
			line-height: 18px;
			padding-left: 13px;
			.box-strong,
			span#total-box {
			    font-weight: 700;
			}
		}
		&.product-per-boxes{
			margin-bottom: 20px;
		}
	}
}