.navigation, .page-header .header.panel, .header.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main{
  @extend .row
}
html {
  scroll-behavior: smooth;
}
input[type=number]::-webkit-inner-spin-button {
    opacity: 1
}
input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
}
._keyfocus *:focus, input:not([disabled]):focus, textarea:not([disabled]):focus, select:not([disabled]):focus{
	box-shadow: 0 0 3px 1px $primary;
}
.page-main,
.page-wrapper{
	display: block !important;
}
#maincontent .column.main {
    padding-left: 0px;
    padding-right: 0px;
}
#maincontent .page-title-wrapper{
	padding-left: 15px;
	padding-right: 15px;
}

//====================
//SVG ICONS
//=======================
.svg-fill-primary{
	fill:$primary;
}

.page-products .columns{
  @include breakpoint(small down) {
    position: static;
    padding-top: 0;
  }
}
button:not(.primary){
  box-shadow: none;
}