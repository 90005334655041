//Header style 2

//variables
$header-background:#2C3033;
$top-bar:#1E2224;
$search-bg:#525456;
$search-color:#ffffff;
$nav-background:#2C3033;

//page-header 
header.page-header{
	background:$header-background;


	//top bar
	.panel.wrapper{
		background:$top-bar;
		border:none;

		@include breakpoint(small down) {

		}
	}
  .panel.header{
    @include breakpoint(small up) {
          padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    }
    @include breakpoint(small down) {
      height: 36px;
    }
  }

	//logo content
	.header.content{
		padding:0px;
    @include breakpoint(small upp) {
      padding:0px;
    }

		//Logo
		.logo-center {
			.logo{
				margin:0px !important;
				max-width: 117px;
				padding-top: 6px; 

				@include breakpoint(medium only) {
					max-width: 110px; 
				}
				@include breakpoint(small down) {
					max-width: 90px; 
				}

			}
		}

		//Search
		.block-search{
			float: none;
			width: 335px;
			margin-top: 20px;
			margin-bottom: 20px;
      height: 50px;
      position: relative;
      @include breakpoint(medium down) {
          height: 45px;
          margin-bottom: 10px;
          margin-top: 13px;
      }


			@include breakpoint(medium down) {
				width: 100%;
        order:5;
        margin-left: 15px;
        margin-right: 15px;
			}
      .control{
        margin: 0px;
        border:none;
        padding: 0px;
      }
      label.label{
          position: absolute;
        right: 10px;
        z-index: 9999;
        top: 10px;
        color: #ffffff;
        opacity: 1;
        &:before{
          color: #ffffff;
        }
      }

			.field .control input{
				background:$search-bg;
				color:$search-color;
				font-weight: 500;
				font-size: 16px;
				letter-spacing: 0.5px;
				border:0px;
				height: 50px;
        position: static;
        margin:0px;
          @include breakpoint(medium down) {
            height: 45px;
          }
				
				&::-webkit-input-placeholder{
					color:rgba($search-color,0.9);
				}
			}

			.action.search{
				opacity: 1 !important;

				&:before{
					color: #FFFFFF;
					font-size: 20px;
					line-height: 49px;
				}
			}
		}
	}

	.switcher-options{
		font-size: 14px;
		line-height: 25px;
	}
	.header.links{
		font-size: 14px;
		line-height: 25px;

		//Temp
		li{
			//display: none !important;
			text-transform: uppercase;
      a{

      }
      &:last-child{
        display: none;
      }
    		
			&.nav.item{
				display: inline-block !important;
			}
		}
	}
}
//links
.left-links{
    padding-left: 0px;
    margin-bottom: 0px;
    margin-left: 54px;
    display: flex;
    align-items: center;

    @include breakpoint(small down) {
      margin-left: 0px;
    }

    > li{
      margin-right: 32px;
      margin-bottom: 0px;
      list-style: none;

      @include breakpoint(small down) {
        margin-right: 12px;
        &.link.wishlist{
          display: none;
        }
      }

      

      button.action.switch,
      > a{
        text-transform: uppercase;
        text-decoration: none;
        font-size: 14px;
        color: #ffffff;
        padding-top: 13px;
        padding-bottom: 13px;
        display: block;
      }
      
        > a{
          font-size: 12px;
          color: transparent !important;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          padding: 0;
          font-weight: 400;
          position: relative;
          text-decoration: none;
          width: 32px;
          height: 50px;
          display: block;
          &:before{
            content: "";
            width: 32px;
            position: absolute;
            left: 0;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 24px;
            background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 26 26' viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m21.5 16.5c-1.3-1.3-2.9-2.3-4.6-2.9 1.8-1.3 3-3.3 3-5.7 0-3.8-3.1-6.9-6.9-6.9s-6.9 3.1-6.9 6.9c0 2.4 1.2 4.5 3 5.7-1.7.6-3.3 1.6-4.6 2.9-2.3 2.2-3.5 5.3-3.5 8.5h1.9c0-5.6 4.5-10.1 10.1-10.1s10.1 4.5 10.1 10.1h1.9c0-3.2-1.2-6.3-3.5-8.5zm-8.5-3.5c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1z' fill='%23fff' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");

          }
        }

        &.wishlist{
        	a:before{
        		background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 28 26' viewBox='0 0 28 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m20.1 1c-3.1 0-5.1 1.6-6.1 2.8-1-1.2-3-2.8-6.1-2.8-4.1 0-6.9 3.9-6.9 8.2 0 3.3 1.6 9.7 12.6 15.6.3.1.6.1.8 0 11-5.9 12.6-12.3 12.6-15.6 0-4.4-2.9-8.2-6.9-8.2zm-6.1 22.1c-5.3-2.9-11.3-7.8-11.3-13.9 0-3.4 2-6.5 5.2-6.5 2.4 0 4.4 1.5 5.3 2.9.3.6 1.1.6 1.5 0 0 0 1.8-2.9 5.3-2.9 3.2 0 5.2 3.2 5.2 6.5.1 6.1-5.9 11-11.2 13.9z' fill='%23fff' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");
        	}
      	}
      

    }
    .customer-welcome{
       position: relative;
       &.active .customer-menu{
        display: block;
       }
       .customer-name{
          button.action.switch{
                font-size: 12px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                padding: 0;
                font-weight: 400;
                position: relative;
                text-decoration: none;
                width: 32px;
                height: 50px;
                display: block;

              &:before{
                   content: "";
                   width: 32px;
                   background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 26 26' viewBox='0 0 26 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m21.5 16.5c-1.3-1.3-2.9-2.3-4.6-2.9 1.8-1.3 3-3.3 3-5.7 0-3.8-3.1-6.9-6.9-6.9s-6.9 3.1-6.9 6.9c0 2.4 1.2 4.5 3 5.7-1.7.6-3.3 1.6-4.6 2.9-2.3 2.2-3.5 5.3-3.5 8.5h1.9c0-5.6 4.5-10.1 10.1-10.1s10.1 4.5 10.1 10.1h1.9c0-3.2-1.2-6.3-3.5-8.5zm-8.5-3.5c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1z' fill='%23fff' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");
                   position: absolute;
                   left: 0;
                   height: 100%;
                   background-repeat: no-repeat;
                   background-position: center center;
                   background-size: 22px 25px;
                   top: 0;
                  }
                  &:after{
                    position: absolute;
                    right: -4px;
                    top: 10px;
                  }
            
              &.active-top:hover{
                opacity: 1;
              }
         }
       }
 
    }
  

}

//USP
.top-usp-view{
	display: flex;
	justify-content: center;
    align-items: center;
    color: #ffffff;
    .usp-item{
    	display: flex;
    	align-items: center;
    	font-family: 600;
		font-size: 13px;
		letter-spacing: 0;
		line-height: 16px;
		margin-right: 48px;
    padding-left: 0px;

		&:last-child{
			margin-right: 0px;
		}

		@include breakpoint(medium only) {
			margin-right: 30px;
		}

		@include breakpoint(small down) {
			font-size: 13px;

			&:nth-child(2), 
			&:nth-child(3){
				display: none;
			}
		}

		.moo-usp-icon{
			margin-right: 10px;

			svg{
				max-width: 24px;
        @include breakpoint(small down) {
              max-width: 20px;
              position: relative;
              top: 2px;
        }

				path{
					fill:$primary;
				}
			}
		}
    }
}
//Top cart
  .minicart-wrapper{
  	margin-left: 0px;
       
    @include breakpoint(medium down) {
          margin-right: 60px;
    }

    @include breakpoint(small down) {
      margin-top: 0px;

      &.active{
        position: static;

        .block-minicart{
            width: 100%;
            left: 0;
            right: auto;
            top: 67px;

            &:before,
            &:after{
              right: 68px;
            }
        }

      }
    }

    @include breakpoint(small up) {
       margin-right: 0.9375rem;
    }
    .block-minicart{
      .items-total{
        width: 40%;
      }
    }
    .minicart-items-wrapper{
      max-height: 440px;
      overflow-y: scroll;
      overflow-x: hidden;
      background: /* Shadow covers */ linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      background-attachment: local, local, scroll, scroll;
    }
      .action.showcart{
        height: 100%;
        position: relative;

        &:before{
          background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 32 30' viewBox='0 0 32 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m25.3 21.3c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm0 5.6c.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6-1.6.7-1.6 1.6.8 1.6 1.6 1.6zm6.4-21c.2.3.3.7.2 1l-2.9 11.8c-.1.5-.6.9-1.2.9h-18.5c-.6 0-1-.4-1.2-.9l-3.9-16.3h-3c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.2h4c.6 0 1 .4 1.2.9l1.1 4.5h23.4c.3 0 .6.2.8.5zm-4.7 11.3 2.3-9.4h-21.3l2.2 9.4zm-16.1 4.1c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm0 5.6c.9 0 1.6-.7 1.6-1.6s-.7-1.6-1.6-1.6-1.6.7-1.6 1.6.7 1.6 1.6 1.6z' fill='%23fff'/%3E%3C/svg%3E");
          content: "";
          width: 32px;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 30px;
          color: transparent !important;
        }
        .counter.qty{
          background: transparent;
          height: auto;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          position: absolute;
          top: 12px;
          left: 20px;

          span.counter-number{
              position: relative;
              text-shadow: none;
              background: #EF5427;
              display: flex;
              width: 20px;
              height: 20px;
              justify-content: center;
              align-items: center;
              border-radius: 50px;

            &:before{
              //content: "(";
            }
            &:after{
              //content: ")";
            }
          }
        }
        .text{
          clip: auto;
          position:relative;
          width: auto;
          height: auto;
          color: $dark-gray;
          font-size: 12px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          padding: 5px 0 5px 10px;
          font-weight: 400;
          display: none;

            &:hover{
              color: $primary;
            }
        }
      }

  } 
  //Top mini cart
.mage-dropdown-dialog{
    .block-minicart{
      border:none;
      .block-content{
        .action.close{
          display: none;
        }
        .minicart-items-wrapper{
          .minicart-items{
            .product-item-details{
              position: relative;

              .product-item-name{
              line-height: 1; 
              margin-top: 5px;
                a{
                  color: #000000;
                  font-weight: 700;
                  font-size: 16px;
                  color: #1B1C28;
                  letter-spacing: 0.09px;
                  line-height: 20px;
                  text-transform: uppercase;
                }
              } 

              .product.options {
                  font-size: 15px;
                  .toggle:after{
                    font-size: 13px;
                    color: #000000;
                    margin-left: 8px;
                  }
              }
              .product-item-pricing{
                .price-including-tax, .price-excluding-tax{
                  font-size: 14px;
                }
                .price{
                  font-size: 14px;
                }
                .details-qty{
                  label{
                    display: none;
                  }
                  .item-qty{
                    margin-bottom: 0px;
                    display: inline-block;
                    width: 60px;
                  }
                  .update-cart-item{
                    font-size: 16px;
                    border:1px solid $primary;
                    padding: 5px 10px;
                    margin-left: 10px;
                    line-height: 20px;
                    margin-top: 0px;
                    cursor: pointer;
                    box-shadow: none;
                    &:hover{
                      background:$primary;
                      color: #ffffff
                    }
                  }
                }
              }
              .product.actions{
                  position: absolute;
                  right: 0;
                  top: 25px;
                  margin: 0;
                  .primary{
                    display: none;
                  }
                  .secondary{
                    a.action.delete:before{
                      content: "";
                      width: 20px;
                      height: 20px;
                      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 0 20 20' width='20'%3E%3Cpath d='m1369.56712 56.8743685 8.18162 8.1816196-1.69275 1.6927489-8.18162-8.1816196-8.18162 8.1816196-1.69275-1.6927489 8.18162-8.1816196-8.18162-8.1816196 1.69275-1.6927489 8.18162 8.1816196 8.18162-8.1816196 1.69275 1.6927489z' fill-rule='evenodd' transform='translate(-1358 -47)'/%3E%3C/svg%3E");
                    }
                  }
              }

            }
          }
        }

      }//block-content
    }

    .block-minicart .block-content > .actions > .primary .action.primary{
      font-size: 16px;
      height: 44px;
      line-height: 1;
      padding: 0;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      border:1px solid $primary !important;
      &:focus,
      &:active,
      &:hover{
        background:#ffffff;
        color: $primary !important;
      }
    }
}

//Navigation----------------------
.nav-sections{
  background: $nav-background;
  margin-bottom: 20px;
  border-top: none;

  @include breakpoint(medium down) {
    background: #ffffff;
  }

  .nav-sections-item-content{
    background: transparent;
    .navigation{
      background: transparent;
       &>ul{
        text-align: center;
        @include breakpoint(small down) {
          text-align: left;
          width: 100%; 
        }

        .submenu{
          text-align: left;
        }
       }
    }
    .navigation > ul{
    	padding:0px;
    	border-top: 1px solid rgba(#ffffff,0.25);
    	@include breakpoint(medium up) {
	    	display: flex;
	    	justify-content: space-around;
	    }
    }
  }

  .navigation .level0.active > .level-top, .navigation .level0.has-active > .level-top{
    border:none;
  }
  .navigation .level0 > .level-top{
  	color: #ffffff;
  	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
	padding-top: 15px;
	padding-bottom: 15px;
	text-transform: uppercase;

	&:hover{
		color: $primary;
	}
  }
  .navigation .level0.active > .level-top, .navigation .level0.has-active > .level-top{
  	color: $primary;
  }
}

//Responsive
.cms-index-index{
  @include breakpoint(small down) {
    header.page-header {
        border: none;
        margin: 0;
    }
  }
}
