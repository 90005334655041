.ammenu-menu-wrapper{
	.ammenu-item.-main:hover{
		background:none !important;
	}

	ul.ammenu-item.-child{
		margin-left: 46px;
	}
	.ammenu-categories-container [class*='-col-']{
		margin-left: 0px !important;
		border-right: 1px solid #BBBBBB;
	}
	.ammenu-categories-container [class*='-col-']:last-child{
		border:none;
	}

}
figure.ammenu-icon-block{
	min-width: 24px;
    width: 34px;
    height: 28px;
    mask-image:none !important;
    //mask-image: unset !important;
   // background-color: none !important;
}
 body:not(.-ie) .ammenu-nav-sections .ammenu-menu-wrapper .ammenu-link.current:not(:hover):not(.-hovered):not(.-active) > .ammenu-icon-block:not(.-empty),
body:not(.-ie) .ammenu-nav-sections .ammenu-menu-wrapper figure.ammenu-icon-block:not(.-empty){
	//background-color: none !important;
	mask-image:none !important;

} 
.ammenu-menu-wrapper.-desktop .ammenu-categories-container [data-ammenu-js="parent-subitem"]>.ammenu-link:not(.-level1):after{
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 7 10' viewBox='0 0 7 10'%3E%3Cg fill='none' stroke-width='2'%3E%3Cpath d='m1 9 4-4-4-4' stroke='%23fff'/%3E%3Cpath d='m1 9 4-4-4-4' stroke='%23ef5427'/%3E%3C/g%3E%3C/svg%3E") !important;
	background-size: contain !important;
    height: 12px !important;
}


//Item styling
.ammenu-menu-wrapper.-mobile .ammenu-link.-main, 
.ammenu-menu-wrapper.-desktop .ammenu-link.-level1 {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 15px;
	padding-bottom: 0px;
	letter-spacing: 0.75px;

}

.ammenu-menu-wrapper.-mobile .ammenu-link.-level1, .ammenu-menu-wrapper.-hamburger .ammenu-link.-level1 {
	text-transform: uppercase;
	font-weight: 400;
	@include breakpoint(medium down) {
	 	font-size: 14px;
	 }
}
.ammenu-menu-wrapper.-desktop .ammenu-items{
	 justify-content: space-around;
	 border-top: 1px solid rgba(255,255,255,0.25);
}
.ammenu-menu-wrapper.-desktop .ammenu-item.-main>.ammenu-link{
	font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.ammenu-menu-wrapper.-desktop .ammenu-categories-container .ammenu-link:not(.-level1){
	color: #606060;
	font-size: 15px;
}
.ammenu-menu-wrapper.-desktop .ammenu-categories-container [class*='-col-']:not(.-col-1){
	max-width: inherit !important;
}
.ammenu-menu-wrapper.-desktop .ammenu-submenu-container{
	box-shadow: 0 24px 50px 0 rgba(0,0,0,0.25);
}
.ammenu-content .ammenu-menu-wrapper.-desktop .ammenu-main-container{
	@include breakpoint(medium up) { 
		max-width: 77.5rem;
		margin-right: auto;
	    margin-left: auto;
	    padding-right: 0.9375rem;
    	padding-left: 0.9375rem;

	    .ammenu-items{
	    	
	    }
	}
}
/* Mobile menu */
.header{
	.ammenu-menu-toggle{
		right: 0px;
		left: auto;
		margin: 1px 0;
	    padding: 18px 15px;

	    @include breakpoint(large up) {
	    	//display: none;
	    }
	    @include breakpoint(medium only) {
	    	padding: 24px 15px;
	    }

		.ammenu-icon {
			border-color: #ffffff !important;
		    margin: 3.6px 0;
		    min-width: 20px;
		    width: 24px;
		    border-bottom: 2.2px solid;
		    border-radius: 1px;
		}
	}
}
@include breakpoint(medium down) {
	.ammenu-nav-sections.nav-sections .header.links li > a,
	.ammenu-menu-wrapper.-mobile .ammenu-link.-main{
		color: #16161F !important;
		font-size: 14px;
		font-weight: 400;
    	text-transform: uppercase;
	}
	 .nav-sections.ammenu-nav-sections .ammenu-title.active > .ammenu-link{
	 	color:$primary !important;
	 	font-weight: 400;
	 }
	 .ammenu-nav-sections.-accordion .ammenu-link.-level2{
		font-size: 14px;
	}
	.ammenu-nav-sections.-accordion .ammenu-link.-level3{
		font-size: 15px;
		color: #606060 !important;
		letter-spacing: 0;
		line-height: 20px;
	}
	.ammenu-item >.ammenu-wrapper{
		.ammenu-item >.ammenu-wrapper{
			padding: 5px 0 5px 10px;
		}
	}
	.ammenu-nav-sections.-accordion .ammenu-categories>.ammenu-item>.ammenu-wrapper{
		margin: 0 0 0 10px;
	}
	.ammenu-item > .ammenu-wrapper .ammenu-item > .ammenu-wrapper{
		padding: 5px 0 5px 10px;
	}
	.ammenu-categories > .ammenu-item > .ammenu-wrapper > .ammenu-item > .ammenu-wrapper{
		padding: 5px 0 5px 37px;
	}
	.ammenu-nav-sections .ammenu-item.-main{
		border:none;
	}
	.ammenu-content .ammenu-menu-wrapper .ammenu-icon:before, 
	.ammenu-content .ammenu-menu-wrapper .ammenu-submenu.-collapsed + .ammenu-toggle .ammenu-icon:before,
	.ammenu-content .ammenu-menu-wrapper .ammenu-icon:before,
	.ammenu-content .ammenu-menu-wrapper .ammenu-icon:after{
		    background: #2C3033 !important;
	}
	.ammenu-nav-sections:not(.-hamburger) .ammenu-title{
		width: 50%;
		border-bottom: 1px solid #666;
		background: #ffffff !important;
		color: #16161F !important;
	}
	 .nav-sections.ammenu-nav-sections .ammenu-title > .ammenu-link{
	 	color: #16161F !important;
	 }
	.ammenu-nav-sections .ammenu-tabs-container>.ammenu-title.active{
		color: $primary !important;
		border-color: $primary;
	}

}

//login respnosive
@include breakpoint(medium down) {
	.header .ammenu-menu-toggle{
		height: 66px;
	}
}
@include breakpoint(small down) {
	header span.customer-name {
	    display: block;
	}
	.customer-welcome .action.switch>span {
	    border: 0;
	    clip: rect(0, 0, 0, 0);
	    height: 1px;
	    margin: -1px;
	    overflow: hidden;
	    padding: 0;
	    position: absolute;
	    width: 1px;
	}

	.left-links .customer-welcome .customer-name button.action.switch {
	    box-shadow: none;
	    margin-bottom: 0;
	}
	.customer-welcome .action.switch:hover {
	    background: none;
	    border: none;
	}
	.customer-welcome .customer-menu {
	    display: none;
	}
	.customer-welcome .header.links {
	    min-width: 175px;
	    z-index: 1000;
	}
	.customer-welcome ul {
	    
	    background: #fff;
	    border: 1px solid #bbb;
	    margin-top: 4px;
	    min-width: 100%;
	    z-index: 101;
	    box-sizing: border-box;
	    position: absolute;
	    top: 100%;
	    right: 0;
	    box-shadow: 0 3px 3px rgba(0,0,0,0.15);
	}
	.customer-welcome ul:before {
	    border: 6px solid;
	    border-color: transparent transparent #fff transparent;
	    z-index: 99;
	    content: '';
	    display: block;
	    height: 0;
	    position: absolute;
	    width: 0;
	    right: 10px;
	    top: -12px;
	}
	.customer-welcome li a {
	    color: #333;
	    text-decoration: none;
	    display: block;
	    line-height: 1.4;
	    padding: 8px;
	}

}
//Opening level 1 for mobile
li.ammenu-item.category-item.-main.nav-1{
	.ammenu-submenu-container.ammenu-submenu{
		//display: block !important;
	}
}
