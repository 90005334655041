//layout{
	.catalog-product-view.page-layout-1column{
		.product-info-main{
			@include breakpoint(large up) {
				width: 50%;
			}
			@include breakpoint(medium down) {
				width: 100%;
			}
		}
		.product.media{
			@include breakpoint(large up) {
				width: 48%;
			}
			@include breakpoint(medium down) {
				width: 100%;
			}

		}
	}
//Product Information
.product-info-main {
	.page-title-wrapper{
		padding-left: 0px !important;
		padding-right: 0px !important;
		.page-title{
			font-weight: 500;
			font-size: 32px;
			color: #000000;
			letter-spacing: 1.07px;
			text-align: left;
			line-height: 40px;
			text-transform: uppercase;

			@include breakpoint(small down) {
				font-size: 22px;
				line-height: 30px;
			}
		}
	}
	.moo-product-brand{
		color: $moo-brands-title;
		font-size: $moo-brands-title-font;
		text-transform: uppercase;
		font-weight: 300;
		margin-bottom: 0px;
	}

	.product-reviews-summary{
		float: right;
		.reviews-actions{
			display: none;
		}
		.rating-summary{
			margin-right: 0px;
		}
	}
	.product-info-price{
		display: flex;
		justify-content: space-between;
    	align-items: center;
    	margin-top: 22px;
    	border-bottom: none;

    	.product-info-stock-sku{
    		padding-left: 20px;
    		.stock{
    			display: none;
    		}
    	}
    	.price-box{
			display: block;
			width: auto;
		}
	} 
	.price-box{
		.normal-price{
			.price-label{
				display: none !important;
			}
		}

		.special-price{
			display: inline-block;
			.price-label{
				display: none !important;
			}
		}
		.old-price{
			display: block;
			.price-container{

				.price-label{
					font-size: 16px;
					color: #000000;
					line-height: 20px;
					font-weight: 700;
					opacity: 0.9;
					margin-right: 10px;
					margin-bottom: 0px;
					white-space: nowrap;
					display: inline-block;
				}
			}
			.price-wrapper {
				display: inline-block;
				.price{
					opacity:0.9;
					font-size: 16px;
					line-height: 20px;
					font-weight: 700;
				}
			}
		}
		.price-wrapper{
			.price-container .price{
				font-weight: 700;
				font-size: 48px;
				color: #000000;
				letter-spacing: 0.1px;
				text-align: left;
				line-height: 57px;

				@include breakpoint(small down) {
					font-size: 36px;
					line-height: 49px;
				}

			}
		}
	}
	.moo-product-category{
		font-size: 14px;
		color: #787878;
		letter-spacing: 0.4px;
		text-transform: uppercase;
		display: block;
    	float: left;
	}
	.product.attribute.overview{
		font-size: 15px;
		color: #000000;
		letter-spacing: 0.1px;
		text-align: left;
		line-height: 24px;

		p{
			margin-bottom: 10px;
			font-size: 15px;
			color: #000000;
			letter-spacing: 0.1px;
			text-align: left;
			line-height: 24px;
		}
	}

	.product-add-form{
				.product-options-wrapper{
					.fieldset{
						padding: 0px;
					}
				}

			.box-tocart{
				margin-top: 60px;
				display: block;

				.fieldset{
					margin: 0px;
					padding:0px;
					display: flex;
    				flex-direction: row;

    				.field.qty{
    					display: block;
    					height: 78px;
    					border:1px solid #ACACAC;
    					padding:0px;
    					margin-right: 35px;
    					width: 90px;

    					label.label{
    						font-size: 12px;
    						margin-left: 10px;
    					}
    					.input-text.qty{
    						border: none;
    						box-shadow: none;
    						appearance:auto;
    						margin-bottom: 0;
						    overflow: visible;
						    padding-bottom: 0;
						    height: 40px;
						    font-weight: 700;
							font-size: 18px;
							color: #000000;
							letter-spacing: 0;
							line-height: 18px;
							width: 100%;
    						text-align: left;
    					}
    				}
    				.actions{
    					flex-grow:1;
    					display: block;
    					padding-top: 0px;

    					button#product-addtocart-button{
    						display: block;
    						width: 100%;
    						font-weight: 700;
							font-size: 20px;
							color: #FFFFFF;
							letter-spacing: 3px;
							text-align: center;
							line-height: 18px;
							text-transform: uppercase;
							height: 78px;
							border: none;
							max-width: 400px;
    					}
    				}
				}
			}

	}

	

}//.product-info-main
//tabs
.product{
	.product.data.items > .item.title > .switch{
		height: 42px;
		border:1px solid #787878;
		font-weight: 400;
		font-size: 16px;
		color: #787878;
		letter-spacing: 0.46px;
		text-align: center;
		background: transparent;
		position: relative;
	}
	.product.data.items > .item.title:not(.disabled) > .switch:active, 
	.product.data.items > .item.title.active > .switch, 
	.product.data.items > .item.title.active > .switch:focus, 
	.product.data.items > .item.title.active > .switch:hover{
		background: #3B3D40;
		color: #ffffff;
		border-color: #3B3D40;
	}
	.product.data.items > .item.title:not(.disabled) > .switch:active, 
	.product.data.items > .item.title.active > .switch{
		&:before{
			content:"";
			width: 0; 
		  	height: 0; 
		  	border-left: 10px solid transparent;
		    border-right: 10px solid transparent;
		    border-bottom: 8px solid #ffffff;
		    position: absolute;
		    bottom: -2px;
		    left: 50%;
		    margin-left: -10px;
		}
	}
	.product.data.items > .item.content{
		border:none;
		padding:26px 0;
	}
	.product.info.detailed{
		margin-top: 60px;
	}
	@include breakpoint(small down) {
		.product.data.items>.item.title {
		    margin: 0 -1px 0 0;
		}
		.product.data.items>.item.title {
		    float: left;
		    width: auto;
		}
		.product.data.items>.item.content {
		    margin-top: 43px;
		    box-sizing: border-box;
		    float: right;
		    margin-left: -100%;
		    width: 100%;
		}
		.product.data.items .item.title a:after{
			display: none;
		}
		.product.data.items{
			border:none;
			margin-left: 0px;
			margin-right: 0px;
		}
	}
}

.block.upsell,
.block.related {
    clear: both;
}

//Product-image
.product.media{
	.fotorama__stage{
		box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
	}
	.fotorama__thumb-border{
		border:1px solid $primary;
	}
	.fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
	    margin-top: 24px;
	}
}
.catalog-product-view {
	.moo-breadcrumbs{
		padding-left: 0.9375rem;
	}
}
//breadcrumbs
.breadcrumbs,.moo-breadcrumbs{


	ul.items{
		font-size: 15px;
		text-transform: uppercase;
		font-weight: 400;

		&:before{
			content: "Du er her: ";
			font-size: 15px;
			color: #000000;
			margin-right: 10px;
			text-transform: none;
		}

		li.item{
			font-size: 15px;
			float: none;
			a:visited,a{
				color: #787878;
			}
			strong{
				color: #000000;
			}
			&:after{
				content: "/";
				font-family: $body-font-family;
				font-size: 15px;
				color: #000000;
				line-height: 24px;
			}

			&:last-child:after{
				display: none;
			}
		}
	}
}
//swatches
.swatch-opt{
	.swatch-attribute-label{
		font-weight: 400;
		font-size: 16px;
		color: #000000;
		letter-spacing: 0.1px;
	}
	.swatch-attribute.size,
	.swatch-attribute{
		.swatch-option{
			height: 40px;
			width: 40px;
			border:1px solid #CECECE;
			outline: none;
			background:#ffffff;
			display: flex;
		    justify-content: center;
		    align-items: center;
		    border-radius: 50%;

		    &.text{
				font-weight: 400;
				font-size: 14px;
				color: #000000;
				letter-spacing: 0.7px;
				text-align: center;
				line-height: 20px;
				background: #ffffff;
		    }

			&:hover{
				border:1px solid #787878;
				outline: none;
			}
			&:focus{
				box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
			}
			&.selected{
				outline: none;
				background: #F3F3F3;
				border:1px solid #787878;
			}
		}
	}
}
.swatch-option.text.selected {
    background-color: #F3F3F3 !important;
}
//Blocks
.column.main{
	.block{
		.title{
			strong{
				font-weight: 700;
				font-size: 26px;
				letter-spacing: 2px;
				text-align: left;
			}
		} 
	}
} 
//review
#product-review-container{
	.review-list{
		.block-title strong{
			font-size: 26px;
		}
		.review-items{
			.review-title{
				font-size: 22px;
			}
		}
	}

} 
.fieldset .review-legend.legend{
	font-size: 22px;
}
//Forms
input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"]{
	font-family: $body-font-family;
}
input[type=number]:-webkit-inner-spin-button, 
input[type=number]:-webkit-outer-spin-button {  
   opacity: 1;
}
//label settings globally
legend.label,
span.label,
.col.label,
label.label,
.label{
	background:transparent;
	color: #000000;
}

//review
#reviews{
		.block.review-add{
			.review-form{
				margin:0px;
				padding:0px;
				max-width: 100%;
				width: 100%;

				[type='checkbox'] + label, [type='radio'] + label{
					margin-left: 0px;
				}
				fieldset.fieldset.review-fieldset{
					padding:0px;
					margin: 0px;
				}
				.review-field-ratings .review-control-vote label:before{
					color: $primary;
				}
			}
			.actions-toolbar{
				.primary.actions-primary{
					background:16px;
					text-transform: uppercase;
					padding:10px 30px;
					line-height: 20px;
				}
			}
		}
}
//upsell
.block{
	&.related,
	&.upsell{
		.block-title{
			strong{
				font-weight: 700;
				font-size: 26px;
				color: #000000 !important;
				letter-spacing: 2px;
				text-align: left;
			}
		}
	}
}
//.product.info.detailed
.product.info.detailed{
	@include breakpoint(large up) {
		width: 48%;
	}
	.additional-attributes{
		//width: 100%;
		thead, tbody, tfoot{
			border: none;
			background: transparent;
			tr{	
				@include breakpoint(small down) {
					display: table-row !important;
				}
				td,
				th{
					font-weight: 400;
					font-size: 16px;
					color: #0C1214;
					letter-spacing: 0.11px;
					line-height: 26px;
					@include breakpoint(small down) {
						display: table-cell !important;
						font-size: 15px;
					}
				}
				th.label{
					font-weight: 600;
					@include breakpoint(small down) {
						background: none !important;
					    padding-top: 0;
					    padding-left: 0;

					}
				}
				
			}

		}
		tbody tr:nth-child(even){
				background: transparent;
		}
		
	}
}
.additional-attributes-wrapper.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr td[data-th]:before, 
.additional-attributes-wrapper.table-wrapper .table:not(.totals):not(.cart):not(.table-comparison)>tbody>tr th[data-th]:before{
	@include breakpoint(small down) {
		display: none;
	}
}
.catalog-product-view{
	@include breakpoint(medium down) {
		.column.main{
			display: flex;
			flex-direction: column;
			.product.media{
				order:1;
			}
			.product-info-main{
				order:2;
			}
			.product.info.detailed{
				order:3;
			}
			.block.related{
				order:4;
			}
			.block.upsell{
				order:5;
			}
			
		}
	}
}

//grid
// .catalog-product-view{
// 	.column.main{
// 		 display: grid;
// 		  grid-template-columns: 1fr 1fr;
// 		  grid-template-rows: 1fr 1fr 1fr;
// 		  gap: 0px 0px;
// 		  grid-template-areas:
// 		    "media addtocart"
// 		    "info addtocart";

// 		    .product-info-main{
// 		    	grid-area: addtocart;
// 		    	width: 100% !important
// 		    }
// 		    .product.media{
// 		    	grid-area: media;
// 		    	width: 100% !important;
// 		    }
// 		    .product.info.detailed{
// 		    	grid-area: info;
// 		    }
// 	}
// }