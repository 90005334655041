.amsearch-form-container .input-text:focus{
	box-shadow: none;
}
.amsearch-form-container{

	input:focus{
		box-shadow: 0 0 3px 1px #EF5427;

	}
	
	 .amsearch-highlight{
	 	font-size: 14px;
	 }

	.amsearch-wrapper-content>.amsearch-item.product-item{
		display: flex;
		.amsearch-autocomplete-image{
			width: 100px;
		}
		.amsearch-autocomplete-information{
			flex-grow:1;
		}
	}
	.amsearch-results .amsearch-item .item-name{
		font-size: 14px;
	}
	.recent_searches{
		.amsearch-item{
			border:1px solid #787878;
			height: 30px;
			padding:0px;

			&.selected,
			&:hover{
				border-color: $primary;
				color: $primary;
				opacity: 1;
			}
			.item-name{
				line-height: 20px;
				opacity: 0.8;
				font-weight: 400;
				font-size: 12px;
				color: rgba(#1B1C28,0.8);
				letter-spacing: 1.09px;
				padding:6px 16px;
			}
		}
	}
}
.amsearch-item-container:not(.recent_searches) .amsearch-highlight{
	color: $primary;
}
.amsearch-wrapper-content .product-item-link{
	font-weight: 600;
}
.amsearch-results .amsearch-item-container {
    padding-left: 15px;
    padding-right: 15px;
}
.amsearch-item .product-item-description, .amsearch-item .item-description{
	font-size: 12px;
}
.amsearch-wrapper-content .amsearch-wrapper-inner{
	margin-top: 0px;
}
.amsearch-wrapper-content .product-item-details {
    justify-content: center;
} 
.amsearch-more-results .amsearch-link {
    border-bottom: 1px solid $primary;
    color: $primary;
    text-decoration: none;
}