//For full Banner Version : 2
.cms-index-index {
	.nav-sections{
		margin-bottom: 0px;
	}
	.page.messages{
		margin-bottom: 0px;	
	}
	header.page-header{
		margin-bottom: 0px;
	}

	.page-main:not(.expanded) .row{
		max-width: 77.5rem;
		margin-right: auto;
    	margin-left: auto;
    	    display: flex;
	    -webkit-box-orient: horizontal;
	    -webkit-box-direction: normal;
	    -webkit-flex-flow: row wrap;
	    -ms-flex-flow: row wrap;
	    flex-flow: row wrap;
	}
}
.homepage-banner-wrapper{
	@include breakpoint(large up) {
		margin-left: calc(-100vw / 2 + 1210px / 2);
	  margin-right: calc(-100vw / 2 + 1210px / 2);
	}
	@include breakpoint(medium only) {
		margin-left: -15px;
    	margin-right: -15px;
	}
	@include breakpoint(small down) {
		margin-left: -10px;
    	margin-right: -10px;
	}
	.moo-homepage-fullbanner{
		position: relative;

		.moo-hero-orbit{
			position: relative;

			.orbit-container{
				height: 670px !important;
				 @include breakpoint(small down) {
			    	height: 320px !important;
			    }
			}
			.orbit-slide .orbit-item{
				height: 670px;
				background-size: cover;
			    display: block;
			    background-position: center;

			    @include breakpoint(small down) {
			    	height: 320px;
			    }
			}

			.orbit-bullets{
				position: absolute;
				bottom: 35px;
				width: 100%;
				@include breakpoint(small down) {
					bottom: 18px;
				}

				button{
					width: 44px;
					height: 7px;
					border-radius: 4px;
					box-shadow: none !important;

					@include breakpoint(small down) {
				    	width: 32px;
				    	height: 5px;
				    }
				}
			}
			.orbit-controls{
				button{
					background-color:rgba(#2C3033,0.2);
					width: 75px;
					height: 75px;
					border-radius: 50%;
					box-shadow: none !important;
					border:none !important;
					cursor: pointer;
					color: rgba(0,0,0,0) !important;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 33px;
					background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 34 20' viewBox='0 0 34 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='m34 9.6h-32.6'/%3E%3Cpath d='m10.5 1-9 9 9 9'/%3E%3C/g%3E%3C/svg%3E");
					left:90px;
					@include breakpoint(medium down) {
						left:20px;
					}
					@include breakpoint(small down) {
						width: 45px;
						height: 45px;
						background-size: 21px;
					}

					&.orbit-next{
						right: 90px;
						left: auto;
						background-image: url("data:image/svg+xml,%3Csvg enable-background='new 0 0 34 22' viewBox='0 0 34 22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath d='m0 10.6h32.6'/%3E%3Cpath d='m22.5 1 10 10-10 10'/%3E%3C/g%3E%3C/svg%3E");
						
						@include breakpoint(medium down) {
							right:20px;
						}
					}
					&:hover{
						background-color:$primary;
					}
					
				}
			}
		}
		.floating-social{
			position: absolute;
			top: 80px;
			left: 90px;

			@include breakpoint(small down) {
				position: relative;
				left: auto;
				top: auto;
				background: #F8F8F8;
			}
			.moo-social-card{
				padding: 0px;
				margin:0px;
				flex-direction: column;
				@include breakpoint(small down) {
					flex-direction: row;
					    justify-content: center;
    					text-align: center;
    					padding-top: 15px;
				}
				
				li a{
					background:#ffffff;
					padding: 9px;

					&:hover{
						background:rgba(#ffffff,0.7);
					}
				}
			}
		}
	}

}
//Homepage Intro
.homepage-intro-wrapper {
   @include breakpoint(large up) {
		margin-left: calc(-100vw / 2 + 1210px / 2);
	  margin-right: calc(-100vw / 2 + 1210px / 2);
	}
	@include breakpoint(medium only) {
		margin-left: -15px;
    	margin-right: -15px;
	}
	@include breakpoint(small down) {
		margin-left: -10px;
    	margin-right: -10px;
	}
}
.brand-intro{
		background:#F8F8F8;
		padding-top: 80px;
		padding-bottom: 80px;
		text-align: center;
		@include breakpoint(medium only) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		@include breakpoint(small down) {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		h1,h2{
			font-size: 30px;
			color: $body-font-color;
			letter-spacing: 0;
			text-align: center;
			margin-bottom: 27px;

			@include breakpoint(small down) {
				font-size: 20px;
				line-height: 27px;
				margin-bottom: 20px;
			}
		}
		p{
			font-size: 16px;
			color: rgba($body-font-color,0.65);
			letter-spacing: 0;
			text-align: center;
			line-height: 26px;
			margin-bottom: 30px;

			&:last-child{
				margin-bottom: 0px;
			}
		}
		a.hollow.button{
			margin-bottom: 0px;
			margin-top: auto;
			margin-left: auto;
			margin-right: auto;
			font-size: 15px;
			text-transform: uppercase;
			height: 45px;
			line-height: 18px;
			border:1px solid #787878;
			color: #000000;
			font-size: 15px;
			width: 246px;
			display: block;
			max-width: 100%;

			&:hover{
				background:$primary;
				color: #ffffff;
				border-color:$primary; 
			}

			@include breakpoint(medium only) {
				font-size: 12px;
				height: 42px;
				line-height: 20px;
				width: 220px;
			}
		}
	}

.moo-section{
	margin-top: 60px;
	margin-bottom: 60px;
}
//moo-homepage-banner
.moo-homepage-banner{
	.banner-full{
		height: 780px;
		display:flex;
		justify-content:center;
		align-items: center;
		background-size: cover;
		background-position: top center;
		text-decoration: none;

		@include breakpoint(medium only) {
			height: 664px;
		}
		@include breakpoint(small down) {
			height: 500px;
		}

		h2{
			font-size: 70px;
			color: #FFFFFF;
			letter-spacing: 2px;
			text-align: center;
			line-height: 88px;
			text-transform: uppercase;
			transition: 0.7s ease;

			@include breakpoint(small down) {
				font-size: 40px;
				line-height: 54px
			}
		}
		&:hover h2{
			letter-spacing: 10px;
			transition: 0.7s ease;
		}

	}
}
//moo-section
.moo-category-block{
	text-align: center;
	h2{
		text-transform: uppercase;
		margin-bottom: 36px;
		font-size: 30px;
		font-weight: 700;
		letter-spacing: 3px;

		@include breakpoint(small down) {
				font-size: 20px;
		}
	}
	.moo-card.card{
		border:none;
		text-decoration: none;
		height: 490px;
		display: block;
		background-size: cover;
		position: relative;
		display: flex;
		justify-content: center;
	    align-items: center;
	    padding: 0 66px;

	    &:before{
	    	content: "";
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    background: #2C3033;
		    opacity: 0.1;
		    transition: 0.7s ease;
	    }

	    @include breakpoint(medium down) {
				padding: 0 24px;
		}

		@include breakpoint(small down) {
				height: 220px;
		}
		img{
			transition: 0.7s ease;
		}
		&:hover{
			img{
				-webkit-transform: scale(0.9);
				-ms-transform: scale(0.9);
				transform: scale(0.9);
				transition: 0.7s ease;
			}
			h3{
				letter-spacing:2px; 
				transition: 0.7s ease;
			}
			 &:before{
			 	opacity: 0.3;
			 	transition: 0.7s ease;
			 	background: #2C3033;
			 }
		}
		h3{
			font-size: 26px;
			letter-spacing: 1px;
			text-align: center;
			line-height: 34px;
			font-weight: 700;
			text-decoration: none !important;
			text-transform: uppercase;
			margin-bottom: 4px;
			transition: 0.7s ease;
			color: #ffffff;
			position: relative;
			z-index: 1;
		}
		p{
			font-family: 300;
			color: #000000;
			font-size: 16px;
			letter-spacing: 0.8px;
			text-decoration: none !important;
		}
	}
}
//block-bestseller-products
.block-bestseller-products{
	.block-title{
		text-align: center;
		text-transform: uppercase;
	}
	.products .product-item, .moo-products-wrapper .product-item{
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
}

//Filter blocks
.filter-tilte{
	margin-bottom: 30px;
}
.filter-blocks-wrap {

	.filter-link-item{

		.links-inner{
			background: #FFFFFF;
			box-shadow: 0 20px 40px -1px rgba(9,9,9,0.05);
			text-decoration: none;
			display: block;
			transition: all 0.2s ease-in-out;

			&:hover{
				box-shadow: 0 20px 40px -1px rgba(9,9,9,0.3);

				.item-filter-range{
					background-size: 140%;
					transition: all 0.2s ease-in-out;
				}
			}
			.item-filter-range{
				display: flex;
				width: 100%;
				background-color:#9D5359;
				min-height: 100px;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				background-repeat:no-repeat;
				background-size: cover;
				background-position:top;
				font-weight: 500;
				font-size: 14px;
				letter-spacing: 1.7px;
				text-align: center;
				line-height: 24px;
				color:#ffffff;
				text-transform: uppercase;
				transition: all 0.2s ease-in-out;
				background-image: url("data:image/svg+xml,%3Csvg height='102' viewBox='0 0 264 102' width='264' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='evenodd' opacity='.078451'%3E%3Cellipse cx='37.339507' cy='137.5' rx='127.170782' ry='117.5'/%3E%3Cellipse cx='248.388889' cy='13.5' rx='27.598765' ry='25.5'/%3E%3C/g%3E%3C/svg%3E");
				
				&.item-2{
					background-color: #ED9E96;
				}
				&.item-3{
					background-color: #E5A4A1;
				}
				&.item-4{
					background-color: #E37C73;
				}

				.value{
					font-weight:700;
					font-size: 37px;
					letter-spacing: 0;
					text-align: center;
					line-height: 24px;
					text-transform: none;
				}
			}
			.item-desc{
				padding:16px;
				font-size: 14px;
				color: rgba(#000000, 0.8);
				letter-spacing: 0;
				text-align: center;
				line-height: 24px;
				display: block;
			}
		}
	}
}

//amasty-shopby-product-list
#amasty-shopby-product-list{
	.widget.block{
		.block-title strong{
			font-weight: 700;
			font-size: 26px;
			color: #000000;
			letter-spacing: 2px;
			text-align: center;
			text-transform: uppercase;

			@include breakpoint(small down) {
				font-size: 18px;
				letter-spacing: 1.38px;
				line-height: 22px;
			}
		}
	}
}//amasty-shopby-product-list


