//message
.page.messages{
	margin-bottom: 20px;
}
.message{
	color: #000000;
    font-size: 17px;
    padding: 16px 16px 16px 45px;
    border: 1px solid rgba(10, 10, 10, 0.25);

	&.success{
		background-color: #e1faea;
    	color: #000000;
    	font-size: 17px;
    	padding: 16px 16px 16px 45px;
    	border: 1px solid rgba(10, 10, 10, 0.25);

        @include breakpoint(small down) { 
            font-size: 14px;
            padding: 10px 16px 10px 45px;
        }

    	a{
    		color: #000000;
    		font-weight: 600;
    		&:hover{
    			color: #000000;
    		}
    	}
    	> *:first-child:before{
    		top: 30px;
            @include breakpoint(small down) { 
                top: 23px;
                font-size: 20px;
            }
    	}
	}
	&.fail,
	&.error{
		background-color: #f7e4e1;
    	color: #000000;
    	font-size: 17px;
    	padding: 16px 16px 16px 45px;
    	border: 1px solid rgba(10, 10, 10, 0.25);

        @include breakpoint(small down) { 
            font-size: 14px;
            padding: 10px 16px 10px 45px;
        }

    	a{
    		color: #000000;
    		font-weight: 600;

    		&:hover{
    			color: #000000;
    		}
    	}
    	> *:first-child:before{
    		top: 30px;

            @include breakpoint(small down) { 
                top: 23px;
                font-size: 20px;
            }
    	}
	}
}

//Blog
.amblog-form-search .amblog-btn, .amblog-form-comment .amblog-btn{
    background: $primary;

    &:hover{
        background:#111111;
    }
}
.amblog-element-block .amblog-title, .amblog-main-content .amblog-search .amblog-title, .amblog-post-container .amblog-widget-container .amblog-title, .amblog-grid-list>.amblog-item .amblog-widget-container .amblog-title, .amblog-grid-list .slick-slide .amblog-item .amblog-widget-container .amblog-title{
    color: $primary;
}

//Curency switch temp
div#switcher-currency {
    position: absolute;
    left: 50%;
    color: #fff;
    margin-left: -67px;
    top: 10px;
}

div#switcher-currency-trigger {
    display: inline-block;
    cursor: pointer;

    &:after{
         content: '\e622';
        font-family: 'luma-icons';
        position: absolute;
        margin-left: 6px;
    }
}
#switcher-currency{
    ul.dropdown.switcher-dropdown {
        display: none;
        background: #fff;
        padding: 10px 20px;
        min-height: 90px;
        min-width: 120px;
        box-shadow: 0 30px 80px -20px rgba(0,0,0,0.6);
        z-index: 23;
        top: 40px;

        &:before{
                content: "";
                width: 0;
                height: 0;
                position: absolute;
                top: -9px;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 9px solid #F8F8F8;
        }
    }
}
#switcher-currency{
    .switcher-options.active ul.dropdown.switcher-dropdown {
        display: block;
        position: absolute;
    }
}

#switcher-currency{
    strong.label.switcher-label {
        display: inline;
    }
}
#switcher-currency{
    ul.dropdown.switcher-dropdown a{
        text-decoration: none;
    }
    .actions.dropdown.options.switcher-options {
        display: inline-block;
        position: relative;
    }
}
.column.main{
    .block{
        .title{
            strong{
                font-weight: 700;
                font-size: 22px;
                color: $primary;
                letter-spacing: 2.2px;
                text-transform: uppercase;
                margin-bottom: 20px;

                @include breakpoint(small down) { 
                    font-size: 18px;
                }
            }
        }
    }
}

//Laoder
.loading-mask{
    background: rgba(255,255,255,0.72);
    backdrop-filter: saturate(180%) blur(5px);
    -webkit-backdrop-filter: saturate(180%) blur(5px);
}

//CMS Pages
.cms-page-view {
    .page-main .page-title-wrapper{
        @extend .column;
    }
}
.coming-soon {
    font-size: 23px;
}
.cms-section {
    margin-bottom: 60px;
    h3{
        font-size: 21px;
        margin-top: 30px;
        font-weight: 500;
    }
}

//gdpr
.aw-gdpr__customer-consent-popup .modal-header .action-close {
    display: block;
}
.modal-popup._inner-scroll .modal-content{
    text-align: center;
}

.aw-gdpr__guest-consent-popup .modal-inner-wrap .buttons, .aw-gdpr__customer-consent-popup .modal-inner-wrap .buttons {
    margin-top: 60px;
    display: flex;
    text-align: center;
    flex-flow: row wrap;
    justify-content: center;
}
.aw-gdpr__guest-consent-popup .modal-inner-wrap .buttons .action, .aw-gdpr__customer-consent-popup .modal-inner-wrap .buttons .action {
    padding: 13px 24px;
    width: auto;
    display: block;
    font-weight: 400;
    height: 45px;
    margin-top: 0;
    cursor: pointer;
}
.aw-gdpr__guest-consent-popup .modal-inner-wrap .buttons .action.secondary, .aw-gdpr__customer-consent-popup .modal-inner-wrap .buttons .action.secondary {
    background: #fff;
    color: #000;
    margin-left: 7px;
    border: 1px solid #EF5427;
    border-radius: 35px;
    font-size: 15px;
    font-weight: 400;
    &:hover{
        background: #EF5427;
        color:#ffffff;
    }
}

//table
table tbody tr:nth-child(even){
    background: #ffffff;
}