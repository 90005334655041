//OmOss Cms Pages
.cms-test-m,
.cms-kontakt-oss,
.cms-om-oss,
.cms-about-us, {
	#maincontent .column.main{
		padding-bottom: 0px;
		@include breakpoint(small down) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	@include breakpoint(small down) {
		.page.messages{
			margin-bottom: 0px;
		}
	}
}

.om-banner{
	margin-bottom: 50px;
	@include breakpoint(small down) {
		margin-bottom: 10px
	}
}
.om-content{
	padding-top: 40px;
	padding-bottom: 50px;
	@include breakpoint(small down) {
			padding-bottom: 34px;
		}

	h1{
		font-weight:600;
		font-size: 52px;
		color: #111111;
		letter-spacing: 0;
		position: relative;
		@include breakpoint(small down) {
			font-size: 32px;
		}

		&:before{
			content: "";
			width: 70px;
			height: 1px;
			background: #BEBEBE;
			position: absolute;
			top: -23px;
		}
	}
	p{
		font-weight: 400;
		font-size: 16px;
		color: rgba(#000000,0.6);
		letter-spacing: 0;
		line-height: 32px;
		@include breakpoint(small down) {
			line-height: 28px;
		}
	}
}

//Image box
.om-image-box {
    position: relative;

    &:before {
	    content: "";
	    width: 100vw;
	    height: 90px;
	    background: #2C3033;
	    position: absolute;
	    left: 0;
	    z-index: -1;
	    bottom: 0;
	    @include breakpoint(large up) {
	    	margin-left: calc(-100vw / 2 + 1210px / 2);
	    }
	    

	    @include breakpoint(small down) {
	    	margin-left: 0;
	    	left: -10px;
	    	bottom: -5px;
	    	height: 100px;
	    }

	    @include breakpoint(medium only) {
	    	margin-left: 0;
	    	left: -15px;
	    	bottom: -5px;
	    	height: 74px;
	    }
	}
	@include breakpoint(small down) {
		.left-image{
			margin-bottom: 16px;
		}
	}
}
//om-bottom blackout
.om-bottom{
	padding-bottom: 72px;
	padding-top: 90px;
	position: relative;

	 @include breakpoint(medium only) {
	 	padding-top: 60px;
	 }
	 @include breakpoint(small down) {
	 	padding-top: 44px;
	 	padding-bottom: 0px;
	 }

	&:before {
	    content: "";
	    width: 100vw;
	    height: 100%;
	    background: #2C3033;
	    position: absolute;
	    left: 0;
	    z-index: -1;
	    bottom: 0;

	    @include breakpoint(large up) {
	    	margin-left: calc(-100vw / 2 + 1210px / 2);
	    }
	    

	    @include breakpoint(small down) {
	    	margin-left: 0;
	    	left: -10px;
	    }
	    @include breakpoint(medium only) {
	    	margin-left: 0;
	    	left: -15px;
	    }
	}
	.bottom-top{
		padding-top: 72px;
		padding-bottom: 50px;
		border-top:1px solid rgba(#ffffff, 0.15);
		border-bottom:1px solid rgba(#ffffff, 0.15);

		 @include breakpoint(medium down) {
		 	border-top: none;
		 	padding-top: 0px;
		 }
		 @include breakpoint(small down) {
		 	padding-bottom: 28px;
		 }
	}
	.bottom-bottom{
		padding-top: 72px;
		padding-bottom: 72px;

		@include breakpoint(small down) {
		 	padding-top: 44px;
		 }
	}

	h2{
		font-weight: 400;
		font-size: 30px;
		letter-spacing: 0;
		line-height: 46px;

		@include breakpoint(medium only) {
			font-size: 22px;
			line-height: 30px;
		}
		@include breakpoint(small down) {
			font-size: 20px;
			line-height: 30px;
		}
	}
	p{
		font-weight: 400;
		font-size: 16px;
		letter-spacing: 0;
		line-height: 32px;

		@include breakpoint(small down) {
			line-height: 28px;
		}
	}
	&.blackout{
		background:#2C3033;

		h2{
			color: #FFFFFF;
		}
		p{
			color: rgba(#ffffff,0.7);
		}
	}

	
}

//Kontakt us
.kontakt-header{
	margin-top: 90px;
	text-align: center;

	h1{
		font-weight: 700;
		font-size: 30px;
		color: #111111;
		letter-spacing: 3px;
		margin-bottom: 16px;
	}
	p{
		font-weight: 400;
		font-size: 16px;
		color: rgba(#111111,0.8);
		letter-spacing: 0.3px;
	}
}
.kontakt-cta{
	background: #F8F8F8;
	position: relative;
	padding-bottom: 112px;
	margin-top: 0px;
	padding-top: 60px;

	&:before {
	    content: "";
	    width: 100vw;
	    height: 100%;
	    background: #F8F8F8;
	    position: absolute;
	    left: 0;
	    z-index: -1;
	    bottom: 0;

	    @include breakpoint(large up) {
	    	margin-left: calc(-100vw / 2 + 1210px / 2);
	    }
	    

	    @include breakpoint(small down) {
	    	margin-left: 0;
	    	left: -10px;
	    }
	    @include breakpoint(medium only) {
	    	margin-left: 0;
	    	left: -15px;
	    }
	}

	form#contact-form{
		float: none;
		width: 720px;
		margin: 0 auto;
		max-width: 100%;
		input,textarea{
			border:1px solid #ACACAC;
			border-radius: 1px;
			box-shadow: none;
			font-weight: 300;
			font-size: 17px;
			color: #000000;
			letter-spacing: 0;

			&:focus{
				border-color: $primary;
			}

			&::-webkit-input-placeholder {
                opacity: 0.54;
            }
            
            &::-moz-placeholder {
                opacity: 0.54;
            }
            
            &:-ms-input-placeholder {
                opacity: 0.54;
            }
		}
		input{
			height: 58px;
		}

		.fieldset{
			padding: 0px;
			margin: 0px;
			>.field, .fieldset>.fields>.field{
				margin-bottom: 23px;
			}
			> legend, > br{
				display: none;
			}
			.field.note.no-label{
				font-weight: 400;
				font-size: 20px;
				color: rgba(#121212,0.8);
				letter-spacing: 0.22px;
				text-align: center;
				line-height: 34px;
				margin-bottom: 57px;
			}
		}
		.actions-toolbar{
			.primary{
				float: none;
    			width: 100%;
    			margin-top: 11px;
			}
			button.action.submit.primary{
				margin-right: 0px;
				width: 100%;
				height: 60px;
				box-shadow: 0 2px 6px 0 rgba(0,0,0,0.11);
				border-radius: 30px;
				font-weight: 700;
				font-size: 16px;
				color: #FFFFFF;
				letter-spacing: 0.8px;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}
}