.page-footer {
	background: transparent;
	padding-bottom: 0 !important;

	.footer.content {
		padding: 0px;
		width: 100%;
		border: none;
		max-width: 100%;
		background: transparent;

		//temp-hide
		.newsletter,
		.links,
		.copyright,
		.bugs {
			//display: none;
		}

	}

}
.footer.content{
	.moo-footer-wrap {
		background: $moo-footer-background;
		padding-top: 60px;

		@include breakpoint(small down) {
			padding-top: 22px;
		}

		&.moo-dark-footer{
			background: #000000;
			border-top: 1px solid rgba(255, 255, 255, 0.3);
		}

		h4 {
			margin-bottom: 12px;
			font-size: 16px;
			font-weight: 600;
		}
		&.moo-dark-footer h4{
			color: #ffffff;
		}

		p {
			font-size: 15px;
			color: rgba(#000000, 0.55);
			letter-spacing: 0;
			line-height: 24px;
		}
		&.moo-dark-footer p{
			color:#787878;
		}

		.moo-links {
			&.links>li{
				background: none;
				border:none;
				padding:0px;
				margin: 0px;
				font-size: 15px;
				color: rgba(#000000, 0.55);
				letter-spacing: 0;
				line-height: 24px;

				a{
					font-size: 15px;
					color: rgba(#000000, 0.55);
				}
			}
			li a {
				font-size: 15px;
				color: rgba(#000000, 0.55);
				letter-spacing: 0;
				line-height: 24px;
				@include breakpoint(small down) {
					padding: 0px;
				}
			}


		}
		&.moo-dark-footer .moo-links li a{
			color: #787878;
			&:hover{
				color: #ffffff;
			}
		}

		.moo-payment-shipping {
			margin-bottom: 34px;
			@include breakpoint(small down) {
				margin-bottom: 0px;
			}

			.moo-payment{
				flex-direction:column;
				@include breakpoint(small down) {
					margin-bottom: 36px;
				}
			}
		}

		.moo-footer-info {
			
			.moo-footer-info-grid{
				padding-top: 0px;
				padding-bottom: 30px;
				//border-top: 1px solid rgba(#9D9999, 0.18);
				border-bottom: 1px solid rgba(#9D9999, 0.18);

				@include breakpoint(small down) {
					padding-bottom: 0px;
					padding-top: 22px;
				}
				
				.moo-footer-links{
					flex-direction:column;
					@include breakpoint(small down) {
						margin-bottom: 36px;
					}
				}
			}
		}

	}
}
.footer.content ul{
	@include breakpoint(medium down) {
		padding-right: 0px !important; 
	}
}
//cards
.moo-logo-card {
	padding-left: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	
	li {
		list-style: none;
		background: #ffffff;
		height: 52px;
		width: 82px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 4px !important;
		padding: 7px;
		margin-bottom: 5px !important;
	}

	&.shipping-card li {
		margin-right: 8px !important;
	}

}
.moo-dark-footer .moo-logo-card {
	li{
		background:rgba(255,255,255,0.18)
	}
}
//Social
.moo-social-card {
	padding-left: 0px;
	padding-right: 0px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;

	li {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 8px !important;
		margin-bottom: 8px !important; 

		a {
			background: $moo-social-color;
			width: 40px;
			height: 40px;
			border-radius: 50px;
			display: flex;
			text-align: center;
			line-height: 40px;
			padding: 8px;
		    justify-content: center;
		    align-items: center;

			&:hover {
				background: $primary;
			}

		}

	}

}
.moo-dark-footer .moo-social-card{
	li a{
		background:transparent;
		border: 1px solid #ffffff;
		&:hover{
			background:rgba(255,255,255,0.1)
		}
	}
}

//moo-newsletter
.moo-newsletter {
	background: $moo-newsletter-bg;
	text-align: center;
	border-top: 1px solid #BBBBBB;
		padding: 40px 15px;

	.newsletter-inner {
		max-width: 860px;
		width: 100%;
		margin: 0 auto;
	}
	.input-group{
		@include breakpoint(small down) {
			flex-direction:column;
		}
	}

	.moo-title {
		h2 {
			font-size: 26px;
			letter-spacing: 2px;
			text-align: center;
			text-transform: uppercase;

			@include breakpoint(small down) {
				font-size: 18px;
				line-height: 22px;
				letter-spacing: 1.38px;
			}
		}

		p {
			font-size: 16px;
			letter-spacing: 0;
			text-align: center;
			line-height: 22px;
			margin-bottom: 30px;
			font-weight: 300;
		}

	}

	.newsletter.field {
		width: 100%;

		input {
			height: 60px;
			border-color: #000000;
			font-size: 16px;
			box-shadow: none;
			font-weight: 300;

			&:focus {
				box-shadow: 0 0 3px 1px $primary;
			}

		}

	}

	button {
		font-weight: 600;
		font-size: 16px;
		color: #FFFFFF;
		letter-spacing: 0.8px;
		text-align: center;
		text-transform: uppercase;
		border: none !important;
		padding-left: 24%;
		padding-right: 24%;

		@include breakpoint(small down) {
			padding-left: 0px;
			padding-right: 0px;
			height: 58px !important;
		    margin-top: 10px !important;
		    width: 100%;
		}
	}

}

//Footer contact
.moo-footer-contact {
	text-align: center;
	padding: 60px 0 44px 0;
	background:#ffffff;

	@include breakpoint(small down) {
		padding: 40px 0 40px 0;
	}

	&.moo-contact-dark{
		background: #000000;
	}
	.row .moo-foot-cont-item:last-child{
		position: relative;

		&:before{
			content: "";
			width: 1px;
			height: 100%;
			left: -0.5px;
			top: 0px;
			position: absolute;
			background:#979797;
			@include breakpoint(small down) {
				display: none;
			}
		}
		

	}

	.moo-head{
		margin-bottom: 45px;
		@include breakpoint(small down) {
			margin-bottom: 0px;
		}
		p{
			font-size: 16px;
		}
	}
	h2{
		font-weight: 700;
		color: #000000;
		letter-spacing: 3px;
		font-size: 30px;
		line-height: 41px;
		text-align: center;
		margin-bottom: 20px;
		text-transform: uppercase;
		@include breakpoint(small down) {
			font-size: 20px;
		}
	}
	&.moo-contact-dark h2{
			color: #ffffff;
	}

	h3{
		font-size: 17px;
		font-weight: 600;
		color: #000000;
		letter-spacing: 0.2px;
		text-align: center;

		@include breakpoint(small down) {
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0px;
			font-weight: 400;
			text-transform: uppercase;
			word-break: break-all;
		}
	}
	&.moo-contact-dark h3{
			color: #ffffff;
	}

	p {
		font-size: 15px;
		color: rgba(#000000, 0.6);
		letter-spacing: 0;
		text-align: center;
		line-height: 23px;

		@include breakpoint(small down) {
			//display: none;
		}
	}
	&.moo-contact-dark p{
			color: #787878;
	}

	&.only-two{
		.moo-foot-inner{
			padding: 1rem 4rem;

			@include breakpoint(medium down) {
				padding:0px;
			}
		}
	}

	.moo-foot-inner {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 1rem;

		@include breakpoint(medium down) {
			padding:0px;
		}
	}

	a.button{
		margin-bottom: 0px;
		margin-top: auto;
		margin-left: auto;
		margin-right: auto;
		font-size: 15px;
		text-transform: uppercase;
		height: 45px;
		line-height: 19px;
		border:1px solid #787878;
		color: #000000;
		font-size: 15px;
		width: 246px;
		display: block;

		&:hover{
			background:$primary;
			color: #ffffff;
			border-color:$primary; 
		}

		@include breakpoint(medium only) {
			font-size: 12px;
			height: 42px;
			line-height: 20px;
			width: 220px;
		}
		@include breakpoint(small down) {
			display: none;
		}
	}
	&.moo-contact-dark a.button{
		border: 1px solid #ffffff;
    	color: #ffffff;

    	&:hover{
    		background: #ffffff;
    		color: #000000;
    	}
	}
	.moo-foot-cont-item {
		@include breakpoint(small down) {
				margin-bottom: 30px;

			&:last-child{
				margin-bottom: 0px;
			}
		}
		.moo-icon {
		    height: 60px;
		    margin-bottom: 10px;

		    @include breakpoint(small down) {
		    	height: 40px;
		    	svg{
		    		height: 40px;
		    	}
			}
		}

		&:hover .primary-hover{
			fill: rgba($primary, 0.3);
		}
	}

}

.button.dark-outline,
a.button.dark-outline {
	border: 1px solid $moo-dark-outline;
	background: transparent;
	font-size: 15px;
	color: #000000;
	letter-spacing: 0;
	text-align: center;
	height: 46px;
	line-height: 46px;
	padding: 0 20px;

	&:hover {
		background: $moo-dark-outline;
		color: #ffffff;
	}

}

//copyright
.copyright-wrap{
	 background: $moo-footer-background;
	 padding-top:16px;
	 padding-bottom:20px;

	 @include breakpoint(small down) {
	 	text-align:center; 
	 }

	 a,p{
	 	font-weight: 400;
		font-size: 12px;
		color: #5C5451;
		letter-spacing: 0;
	 }
	 p{
	 	@include breakpoint(small down) {
		 	width: 100%;
			float: none;
			display: block;
			text-align: center;
		 }
	 }
	 a{
	 	text-decoration: underline;
	 }
	 span.moo-cokies {
	    display: inline-block;
	    position: relative;
	    margin-right: 5px;
	    top: 5px;
	}
	&.dark-copyright{
		background:#000000;
		a,p{
			color:#787878;
		}
	}
}

//temp removing footer
.moo-footer-contact.only-two {
    //display: none;
}
.cms-index-index .moo-footer-contact.only-two{
	display: block;
}
